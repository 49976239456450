import { dataMaintenanceActions } from '../store';
import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';

function handleSuccess( response ) {
  return {
    data: response.data
  } 
}

export const PriceApi = {
  importPrice: ( action: { token: string,request } ) => {
    return AxiosClient
      .post( '/price/v1/create/file',
        {pricingFile: action.request},
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },

  getPrice: ( action: { token: string, page:number, limit:number, materialCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.materialCode ? `${EUrlParams.MaterialCode}=` + action.materialCode + '&' : '' ) + queryParams;
    
    return AxiosClient
      .get( `/price/v1/read?${queryParams}`,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: dataMaintenanceActions.SETPRICE,
          data: response.data,
          searchKey: action.materialCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            type: dataMaintenanceActions.SETPRICE,
            data: [],
            searchKey: action.materialCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.PriceKey );
      } )
  },
    
}