import { Dispatch } from 'react';
import { IState, IDataMaintainanceAction, IChunkUpload } from '../../../types';
import { EDataMaintenanceType } from '../../data/Constants';
import { updateMaintenanceData,updateMaintenanceSearchData } from '../../services/DataHelperFunctions';

export const dataMaintenanceActions = {
  IMPORTPRICE: 'dataMaintainanceActions-IMPORTPRICE',
  IMPORTIMAGES: 'dataMaintainanceActions-IMPORTIMAGES',
  IMPORTSALESTEXT: 'dataMaintainanceActions-IMPORTSALESTEXT',
  IMPORTPRODUCTHIERARCHY: 'dataMaintainanceActions-IMPORTPRODUCTHIERARCHY',

  GETSALESTEXT: 'dataMaintainanceActions-GETSALESTEXT',
  SETSALESTEXT: 'dataMaintainanceActions-SETSALESTEXT',
  GETIMAGES: 'dataMaintainanceActions-GETIMAGES',
  SETIMAGES: 'dataMaintainanceActions-SETIMAGES',
  GETPRODUCTHIERARCHY: 'dataMaintainanceActions-GETPRODUCTHIERARCHY',
  SETPRODUCTHIERARCHY: 'dataMaintainanceActions-SETPRODUCTHIERARCHY',
  COMPAREPRODUCTHIERARCHY: 'dataMaintainanceActions-COMPAREPRODUCTHIERARCHY',
  SETCOMPAREPRODUCTHIERARCHY: 'dataMaintainanceActions-SETCOMPAREPRODUCTHIERARCHY',
  RESETCOMPAREPRODUCTHIERARCHY: 'dataMaintainanceActions-RESETCOMPAREPRODUCTHIERARCHY',
  PUBLISHPRODUCTHIERARCHY: 'dataMaintainanceActions-PUBLISHPRODUCTHIERARCHY',
  GETPRICE: 'dataMaintainanceActions-GETPRICE',
  SETPRICE: 'dataMaintainanceActions-SETPRICE',
  GETLANGUAGEASSOCIATION: 'dataMaintainanceActions-GETLANGUAGEASSOCIATION',
  SETLANGUAGEASSOCIATION: 'dataMaintainanceActions-SETLANGUAGEASSOCIATION',
  GETLANGUAGES:'dataMaintainanceActions-GETLANGUAGES',
  SETLANGUAGES:'dataMaintainanceActions-SETLANGUAGES',
  GETLANGUAGEDETAILS:'dataMaintainanceActions-GETLANGUAGEDETAILS',
  UPDATELANGUAGEASSOCIATION: 'dataMaintainanceActions-UPDATELANGUAGEASSOCIATION',
  DELETELANGUAGE:'dataMaintainanceActions-DELETELANGUAGE',
  UPDATELANGUAGE:'dataMaintainanceActions-UPDATELANGUAGE',
  CREATELANGUAGE:'dataMaintainanceActions-CREATELANGUAGE',
  DELETELANGUAGEASSOCIATION: 'dataMaintainanceActions-DELETELANGUAGEASSOCIATION',
  GETPRICELISTMAP: 'dataMaintainanceActions-GETPRICELISTMAP',
  SETPRICELISTMAP: 'dataMaintainanceActions-SETPRICELISTMAP',
  GETPRICELISTMAPDETAILS: 'dataMaintainanceActions-GETPRICELISTMAPDETAILS',
  GETCOUNTRYLIST: 'dataMaintainanceActions-GETCOUNTRYLIST',
  SETCOUNTRYLIST: 'dataMaintainanceActions-SETCOUNTRYLIST',
  UPDATEPRICELISTMAP: 'dataMaintainanceActions-UPDATEPRICELISTMAP',
  GETPRODUCTCATALOG: 'dataMaintainanceActions-GETPRODUCTCATALOG',
  SETPRODUCTCATALOG: 'dataMaintainanceActions-SETPRODUCTCATALOG',
  GETPRODUCTCONFIGURATION: 'dataMaintainanceActions-GETPRODUCTCONFIGURATION',
  SETPRODUCTCONFIGURATION: 'dataMaintainanceActions-SETPRODUCTCONFIGURATION',
  GETMARKETS: 'dataMaintainanceActions-GETMARKETS',
  GETMARKETDETAILS: 'dataMaintainanceActions-GETMARKETDETAILS',
  SETMARKETS: 'dataMaintainanceActions-SETMARKETS',
  DELETEMARKET:'dataMaintainanceActions-DELETEMARKET',
  UPDATEMARKET:'dataMaintainanceActions-UPDATEMARKET',
  CREATEMARKET:'dataMaintainanceActions-CREATEMARKET',
  GETMARKETASSOCIATION: 'dataMaintainanceActions-GETMARKETASSOCIATION',
  SETMARKETASSOCIATION: 'dataMaintainanceActions-SETMARKETASSOCIATION',
  UPDATEMARKETASSOCIATION: 'dataMaintainanceActions-UPDATEMARKETASSOCIATION',
  DELETEMARKETASSOCIATION: 'dataMaintainanceActions-DELETEMARKETASSOCIATION',
  SETUPLOADFILE: 'dataMaintainanceActions-SETUPLOADFILE',
  REMOVEUPLOADFILE: 'dataMaintainanceActions-REMOVEUPLOADFILE',
  SETCHUNKFILEUPLOAD: 'dataMaintainanceActions-SETCHUNKFILEUPLOAD'
}

const reducerActionMapping = {
  [dataMaintenanceActions.SETSALESTEXT]: EDataMaintenanceType.SalesText,
  [dataMaintenanceActions.SETIMAGES]: EDataMaintenanceType.Images,
  [dataMaintenanceActions.SETPRODUCTHIERARCHY]: EDataMaintenanceType.ProductHierarchy,
  [dataMaintenanceActions.SETPRICE]: EDataMaintenanceType.Prices,
  [dataMaintenanceActions.SETLANGUAGEASSOCIATION]: EDataMaintenanceType.LanguageAssociation,
  [dataMaintenanceActions.SETLANGUAGES]: EDataMaintenanceType.Language,
  [dataMaintenanceActions.SETPRICELISTMAP]: EDataMaintenanceType.PriceListMap,
  [dataMaintenanceActions.SETPRODUCTCATALOG]: EDataMaintenanceType.ProductCatalog,
  [dataMaintenanceActions.SETPRODUCTCONFIGURATION]: EDataMaintenanceType.ProductConfiguration,
  [dataMaintenanceActions.SETMARKETS]: EDataMaintenanceType.Market,
  [dataMaintenanceActions.SETMARKETASSOCIATION]: EDataMaintenanceType.MarketAssociation,
  [dataMaintenanceActions.SETCOMPAREPRODUCTHIERARCHY]: EDataMaintenanceType.CompareProductHierarchy
}

const initialState = {
  data: [],
  salesText: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  images: { 
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  productHierarchy: {    
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  compareProductHierarchy:{
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  prices: { 
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  languageAssociation: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  languages: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  priceListMap: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  productCatalog: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  productConfiguration: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  markets: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  marketAssociation: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  countries:[],
  uploadFile: {
    file: {},
    dropzone:null,
  },
  chunkUpload:{
    type:'',
    fileName:'',
    fileId:'',
    isUploaded: false,
    uploadValue:0,
    isUploadProgress:false,
  }
}

export const dataMaintainanceDispatcher = ( dispatch: Dispatch<IDataMaintainanceAction> ) => ( {
  importSalesText:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-IMPORTSALESTEXT', token, request } ),
  importImages:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-IMPORTIMAGES', token, request } ),
  importProductHierarchy: ( token: string,request ) => dispatch( { type: 'dataMaintainanceActions-IMPORTPRODUCTHIERARCHY', token, request } ),
  importPrice:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-IMPORTPRICE', token, request } ),
  getSalesText:( token: string, page:number, limit:number, materialCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETSALESTEXT', token, page, limit, materialCode } ),
  getImages:( token: string, page:number, limit:number, materialCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETIMAGES', token, page, limit, materialCode } ),
  getProductHierarchy:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETPRODUCTHIERARCHY', token, page, limit, searchCode } ),
  compareProductHierarchy:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-COMPAREPRODUCTHIERARCHY', token, page, limit, searchCode } ),
  resetCompareProductHierarchy:()=>dispatch( {type:'dataMaintainanceActions-RESETCOMPAREPRODUCTHIERARCHY'} ),
  publishProductHierarchy:( token: string, activity: EActivity )=>dispatch( {type:'dataMaintainanceActions-PUBLISHPRODUCTHIERARCHY', token, activity } ),
  getPrice:( token: string, page:number, limit:number, materialCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETPRICE', token, page, limit, materialCode } ),
  getLanguageAssociation:( token: string, page:number, limit:number, productId?:string )=>dispatch( {type:'dataMaintainanceActions-GETLANGUAGEASSOCIATION', token, page, limit, productId } ),
  getLanguages:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETLANGUAGES',token, page, limit, searchCode } ),
  getLanguageDetails:( token: string, searchCode:string )=>dispatch( {type:'dataMaintainanceActions-GETLANGUAGEDETAILS', token, searchCode } ),
  updateLanguageAssociation:( token: string, request )=>dispatch( {type:'dataMaintainanceActions-UPDATELANGUAGEASSOCIATION', token, request } ),
  deleteLanguage:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-DELETELANGUAGE',token,request } ),
  updateLanguage:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-UPDATELANGUAGE',token,request } ),
  createLanguage:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-CREATELANGUAGE',token,request } ),
  deleteLanguageAssociation:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-DELETELANGUAGEASSOCIATION',token,request } ),
  getPriceListMap:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETPRICELISTMAP', token, page, limit, searchCode } ),
  getPriceListMapDetails:( token: string, searchCode:string )=>dispatch( {type:'dataMaintainanceActions-GETPRICELISTMAPDETAILS', token, searchCode } ),
  getCountryList:( token: string )=>dispatch( {type:'dataMaintainanceActions-GETCOUNTRYLIST', token } ),
  updatePriceListMap:( token: string,request )=>dispatch( {type:'dataMaintainanceActions-UPDATEPRICELISTMAP', token,request } ),
  getProductCatalog:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETPRODUCTCATALOG', token, page, limit, searchCode } ),
  getProductConfiguration:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETPRODUCTCONFIGURATION', token, page, limit, searchCode } ),
  getMarkets:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( {type:'dataMaintainanceActions-GETMARKETS', token, page, limit, searchCode } ),
  getMarketDetails:( token: string, searchCode:string )=>dispatch( {type:'dataMaintainanceActions-GETMARKETDETAILS', token, searchCode } ),
  deleteMarket:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-DELETEMARKET',token,request } ),
  updateMarket:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-UPDATEMARKET',token,request } ),
  createMarket:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-CREATEMARKET',token,request } ),
  getMarketAssociation:( token: string, page:number, limit:number, productId?:string )=>dispatch( {type:'dataMaintainanceActions-GETMARKETASSOCIATION', token, page, limit, productId } ),
  updateMarketAssociation:( token: string, request )=>dispatch( {type:'dataMaintainanceActions-UPDATEMARKETASSOCIATION', token, request } ),
  deleteMarketAssociation:( token: string,request )=>dispatch( { type: 'dataMaintainanceActions-DELETEMARKETASSOCIATION',token,request } ),
  setUploadFile:( file,importDropzone )=>dispatch( { type: 'dataMaintainanceActions-SETUPLOADFILE', file,importDropzone } ),
  removeUploadFile:( )=>dispatch( { type: 'dataMaintainanceActions-REMOVEUPLOADFILE' } ),
  setChunkFileUpload:( chunkUpload: IChunkUpload )=>dispatch( {type:'dataMaintainanceActions-SETCHUNKFILEUPLOAD',chunkUpload} )
} )

export const dataMaintainanceReducer = ( state = initialState, action:IDataMaintainanceAction = { type: '' } ) => {

  if( Object.keys( reducerActionMapping ).includes( action.type ) ) {
    if( !action.searchKey ) {
      return updateMaintenanceData( state, action, reducerActionMapping[action.type] );
    } else {
      return updateMaintenanceSearchData( state, action, reducerActionMapping[action.type] );
    }
  } else{
    switch( action.type ) {
      case dataMaintenanceActions.SETCOUNTRYLIST:
        return { ...state, countries: action.data };
      case dataMaintenanceActions.SETUPLOADFILE:
        return { ...state, uploadFile: {file:action.file,importDropzone:action.importDropzone} };
      case dataMaintenanceActions.REMOVEUPLOADFILE:
        return { ...state, uploadFile: {file:{}, importDropzone:null } };
      case dataMaintenanceActions.SETCHUNKFILEUPLOAD:
        return { ...state, chunkUpload:action.chunkUpload}
      case dataMaintenanceActions.RESETCOMPAREPRODUCTHIERARCHY:
        return {...state, compareProductHierarchy : {...initialState.compareProductHierarchy,data:{}}} 
    }
  }
  return state;
}

export const dataMaintainanceState = ( state: IState ) => ( { dataMaintainance: state.dataMaintainance } );