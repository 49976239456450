import {
  Box,
  Container,
  Divider,
  Theme,
  useMediaQuery,
  Tab
} from '@mui/material';
import React, { useEffect,useState } from 'react';
import { Routes ,Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAuth } from 'oidc-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Header,
  LoadingScreen,
  Unauthenticated,
  UserManagement,
  TabDrawer,
  RoleManagement,
  ApiKeyManagement,
  ApplicationManagement,
  FeatureFlagsManagement,
  SalesText,
  Prices,
  ProductHierarchy,
  LanguageAssociation,
  Images,
  Configuration,
  Unauthorized,
  LanguageManagement,
  UnsupportedBrowser,
  PriceListMap,
  ProductCatalog,
  ProductConfiguration,
  MarketAssociation,
  MarketManagement
} from '.';

import { applicationSettingsDispatcher, applicationSettingsState, userDispatcher, userState, Combine, errorState, errorDispatcher } from '../store';
import { connect } from 'react-redux';
import { Footer } from './Footer';
import { AlertMessage } from './Layouts/AlertMessage';
import { CommonDialog } from './Layouts/CommonDialog';
import { tabsList }from '../data/Constants';
import { useTranslation } from 'react-i18next';

/**
 * Main component to handle the layout at the top level.
 * Sets the different routes.
 * Sets the current page size in the userSettingsState.
 * @param {IAppRouterProps} props the properties for the app router component
 * @returns {JSX.Element} a container with the UI components at top level
 */

const getMiddleContent = ( token,users,TabValue,handleChange,error,auth,t )=>{
  if( !token ) {
    return <Unauthenticated/>;
  } else if( ( Object.keys( users.details ).length > 0 || error.code ) && ( !users.details.name || !users.details.isAdmin || users.details.isService ) ) { //unauthorize user is not an admin or if user is a service user
    return <Routes>
      <Route path="*" element={ <Navigate to="/NotAuthorized" replace={ true } /> }/>
      <Route path="/NotAuthorized" element={ <Unauthorized/> } />
    </Routes>
  } else{
    return <TabContext value={ TabValue }>
      <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
        <TabList onChange={ handleChange } aria-label="Tab Menu">
          {
            tabsList.map( ( tb )=><Tab className="text-capitalize" label={ t( 'labels.' + tb.id ) } key={ tb.id } value={ tb.id } /> )
          }               
        </TabList>
      </Box>
      
      <TabPanel value="administration" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ TabValue }/>
          <Box className="middle-container">
            <Routes>
              <Route path="/NotAuthenticated" element={ <Unauthenticated/> } />
              <Route path="/signin/oidc" element={ <Navigate to="/users" replace={ true } /> } /> 
              <Route path="/signin/oidc-silent" element={ <Navigate to="/users" replace={ true } /> } />          
              <Route path="/" element= { <UserManagement token={ token } error = { error } auth = { auth } /> } />

              <Route path="/users" element={ <UserManagement token={ token } error = { error } auth= { auth } /> }/>
              <Route path="/users/*" element={ <Navigate to="/users" replace={ true } /> }/>

              <Route path="/roles" element={ <RoleManagement token={ token } error = { error } auth= { auth } /> }/>
              <Route path="/roles/*" element={ <Navigate to="/roles" replace={ true } /> }/>
  
              <Route path="/apikeys" element={ <ApiKeyManagement token={ token } error = { error } auth= { auth } /> }/>
              <Route path="/apikeys/*" element={ <Navigate to="/apikeys" replace={ true } /> }/>

              <Route path="/applications" element={ <ApplicationManagement token={ token } error = { error } auth= { auth } /> }/>
              <Route path="/applications/*" element={ <Navigate to="/applications" replace={ true } /> }/>

              <Route path="*" element={ <Navigate to="/users" replace={ true } /> }/>

            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="dataMaintenance" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ TabValue }/>
          <Box className="middle-container">
            <Routes>
              <Route path="/salesTexts" element={ <SalesText token={ token } error = { error } auth = { auth } /> }/>

              <Route path="/prices" element={ <Prices token={ token } error = { error } auth = { auth } /> }/>

              <Route path="/productHierarchy" element={ <ProductHierarchy token={ token } error = { error } auth = { auth } /> }/>

              <Route path="/images" element={ <Images token={ token } error = { error } auth = { auth } /> }/>

              <Route path="/priceListMap" element={ <PriceListMap token={ token } error = { error } auth = { auth } /> }/>
              
              <Route path="*" element={ <Navigate to="/salesTexts" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="product" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ TabValue }/>
          <Box className="middle-container">
            <Routes>                
              <Route path="/market" element={ <MarketManagement token={ token } error = { error } auth = { auth } /> }/>
              <Route path="/marketAssociation" element={ <MarketAssociation token={ token } error = { error } auth = { auth } /> }/>

              <Route path="/languageAssociation" element={ <LanguageAssociation token={ token } error = { error } auth = { auth } /> }/>
              <Route path="/language" element={ <LanguageManagement token={ token } error = { error } auth = { auth } /> }/>

              <Route path="/productCatalog" element={ <ProductCatalog token={ token } error = { error } auth = { auth } /> }/>
              <Route path="/productConfiguration" element={ <ProductConfiguration token={ token } error = { error } auth = { auth } /> }/>

              <Route path="*" element={ <Navigate to="/market" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="featureFlags" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ TabValue }/>
          <Box className="middle-container">
            <Routes>                
              <Route path="/featureFlags" element={ <FeatureFlagsManagement token={ token } error = { error } auth= { auth } /> }/>
              <Route path="/featureFlags/*" element={ <Navigate to="/featureFlags" replace={ true } /> }/>

              <Route path="*" element={ <Navigate to="/featureFlags" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="configuration">    
        <Routes>
          <Route path="/configuration" element={ <Configuration token={ token }/> }/>
          <Route path="*" element={ <Navigate to="/configuration" replace={ true } /> }/>
        </Routes>
      </TabPanel>

    </TabContext>
  }
}

export function $AppRouter( { setApplicationSettings, applicationSettings,getLoggedInUserDetails,users,error }: any ) {
  const {t} = useTranslation();
  const auth = useAuth();
  const token = auth.userData?.access_token;
  const currentPageSize = {
    isExtraSmall: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'xs' ) && theme.breakpoints.down( 'sm' ) ),
    isSmall: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'sm' ) && theme.breakpoints.down( 'md' ) ),
    isMedium: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'md' ) && theme.breakpoints.down( 'lg' ) ),
    isLarge: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'lg' ) && theme.breakpoints.down( 'xl' ) ),
    isExtraLarge: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'xl' ) ),
  };

  useEffect( () => {
    if ( JSON.stringify( applicationSettings.pageSize ) === JSON.stringify( currentPageSize ) ) {
      return;
    }
    setApplicationSettings( { ...applicationSettings, pageSize: currentPageSize } );
  } );
  
  const [TabValue, setTabValue] = useState<string>( 'administration' );
  const handleChange = ( ...params:[React.BaseSyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  useEffect( () => {
    const currentTab = tabsList.find( tb=>
      tb.childs.includes( window.location.pathname.split( '/' )[1] ) 
    );
    const windowLocation = window.location.pathname.includes( 'configuration' ) ? 'configuration' : 'administration';
    setTabValue( currentTab ? currentTab.id : windowLocation );
  },[] );

  useEffect( () => {
    if( token ) {
      getLoggedInUserDetails( token )
    }
  },[token] )
 
  return (
    <Container maxWidth={ false } className="appRouter root">
      <Header setTabValue={ setTabValue } />    
      <Divider className="divider" />     
      <Box className="appRouterContent">
        <Box sx={ { width: '100%', typography: 'body1' } }>
          {getMiddleContent( token,users,TabValue,handleChange,error,auth,t )}
        </Box>
      </Box>
      <LoadingScreen />
      <Footer />
      <AlertMessage/>
      <CommonDialog/>
      <UnsupportedBrowser/>
    </Container>
  );
}

export const AppRouter = connect(
  Combine( applicationSettingsState, userState, errorState ),
  Combine( applicationSettingsDispatcher, userDispatcher, errorDispatcher )
)( $AppRouter );
