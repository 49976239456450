import { createTheme } from '@mui/material';

export const theme = createTheme( {
  breakpoints: {
    values: {
      xs: 0,
      sm: 750, //default 600
      md: 1100, //default 900
      lg: 1400, //default 1300
      xl: 1600, //default 1536
    },
  },
  palette: {
    primary: {
      main: '#1474A4'
    },
    secondary: {
      main: '#FFFFFF'
    }
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: 'transparent',
          '&::before': {
            background: 'transparent'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '0',
          padding: '0',
          '&.Mui-expanded': {
            minHeight: '0'
          }
        },
        content: {
          margin: '0',
          '&.Mui-expanded': {
            margin: '0'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: '2px',
          marginRight: '2px'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 100000
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          display: 'flex'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0 10px !important'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          margin: '0 5px',
          padding: 0
        },
        message: {
          margin: '0 5px',
          padding: 0
        },
        action: {
          margin: '0 5px',
          padding: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:'red'},
      },
    }
  }
} )
