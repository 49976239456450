import { IStyle } from '../types';
import { colFlex, fixHeight, rowFlex, squareSize, PhilipsBlue } from './StyleHelpers';

export const ActionPanelStyle: IStyle = {
  root: {
    ...rowFlex()
  }
}

export const AppRouterStyle: IStyle = {
  root: {
    ...colFlex(),
    ...squareSize( '100%' ),
    padding: '0'
  },
  secondaryHeader: {
    ...rowFlex( 'space-between', 'center' ),
    width: '100%'
  },
  content: {
    ...rowFlex( 'space-between' ),
    ...squareSize( '100%' ),
    overflow: 'hidden'
  },
  divider: {
    width: '100%'
  }
}

export const BreadcrumbStyle: IStyle = {
  root: {
    ...rowFlex( 'flex-start', 'center' ),
    ...fixHeight( '40px' ),
    width: '100%',
    padding: '0 10px'
  },
  item: {
    color: PhilipsBlue,
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  activeItem: {
    fontWeight: 'bold'
  }
}

export const ConfiguratorStyle: IStyle = {
  root: {
    ...colFlex(),
    ...squareSize( '100%' ),
    padding: '20px'
  },
  content: {
    ...squareSize( '100%' ),
    ...rowFlex(),
    flexWrap: 'wrap',
    overflow: 'auto'
  }
}

export const HeaderStyle: IStyle = {
  root: {
    ...rowFlex()
  },
  toolbar: {
    ...rowFlex( 'space-between', 'center' ),
    ...squareSize( '100%' ),
    padding: '0 10px'
  },
  toolbarSection: {
    ...rowFlex( 'flex-start', 'center' )
  }
}

export const InfoPanelStyle: IStyle = {
  root: {
    ...colFlex( 'flex-start', 'center' ),
    height: '100%',
    width: '37%',
    transition: '.15s',
    padding: '10px',
    overflow: 'hidden',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    background: 'rgb( 238,238,238)',
    minWidth: '340px'
  },
  content: {
    margin: '10px 0',
    overflowWrap: 'anywhere',
    borderBottom: '1px solid rgba(0, 0, 0, 0.32)',
    paddingBottom: '10px'
  },
  disabled: {
    minWidth: '0',
    width: '0',
    padding: '0',
    overflow: 'hidden'
  },
  disabledContent: {
    margin: '0'
  },
  hover: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    boxShadow: '-8px 0 8px -8px black',
    zIndex: '15',
    height: 'unset'
  },
  rootPrice: {
    ...rowFlex( 'space-around', 'center' ),
    width: '100%',
    padding: '0 10px'
  },
  title:{
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight:'1.334',
    margin: '10px 0px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.32)',
    paddingBottom: '10px',
  },
  priceTitle:{
    fontWeight: 'bold',
    fontSize:'1rem',
    fontFamily: 'NeueFrutigerWorldW02-Regular'
  }
}

export const MessageStyle: IStyle = {
  alert: {
    ...rowFlex( 'space-between', 'center' ),
    maxWidth: '400px'
  }
}

export const TabDrawerStyle: IStyle = {
  root: {
    height: '100%'
  },
  content: {
    width:'0'
  },
  drawerOpen: {
    width: '250px'
  }
}

export const SectionStyle: IStyle = {
  root: {
    ...rowFlex(),
    width: '100%',
    flexWrap: 'wrap',
    margin: '0 0 0 10px'
  },
  title:{
    margin: '0',
    fontWeight: '500',
    fontSize: '1.25rem',
    lineHeight: '1.6'
  }
}

export const MultiValueSelectControlStyle: IStyle = {
  chipContainer: {
    ...rowFlex(),
    flexWrap: 'wrap'
  },
  chip: {
    margin: '4px'
  },
  incompatible: {
    background: 'rgba( 255, 0, 0, 0.1 )'
  }
}

export const SubModelControlStyle: IStyle = {
  root: {
    ...colFlex( 'space-between', 'flex-start' ),
    margin: '0 10px 0 0'
  },
  numericInput: {
    width: '100%',
  }
}

export const VariableInputStyle: IStyle = {
  root: {
    ...rowFlex(),
    width: '100%',
    minWidth: '450px',
    maxWidth: '570px',
    margin: '10px'
  },
  lockIcon: {
    margin: '8px'
  }
}

export const SelectControlStyle: IStyle = {
  incompatible: {
    background: 'rgba( 255, 0, 0, 0.1 )'
  }
}

export const ConflictDialogStyle: IStyle = {
  header: {
    background: PhilipsBlue,
    fontSize:'1.2rem !important',
    padding:'0.5rem !important',
    borderBottom: '0.1rem solid rgba(0, 0, 0, 0.12)',
    color:'white'
  },
  footer: {
    background: 'rgb( 238, 238, 238 )',
    borderTop: '0.1rem solid rgba(0, 0, 0, 0.12)'
  },
  tableHeader:{
    fontSize: '1.2rem !important'
  },
  selection:{
    wordWrap: 'normal !important',
    fontSize: '0.9rem !important',
    fontWeight: 'bold !important'
  },
  rowText:{
    fontSize: '0.8rem !important',
    fontWeight: 'bold !important',
    wordBreak : 'break-all',
    wordWrap : 'break-word'
  },
  warning:{
    color:'#ff9800 !important'
  },
  subTableHeader:{
    wordWrap: 'normal !important',
    whiteSpace: 'nowrap !important',
    fontSize: '0.9rem !important',
    fontWeight: 'bold !important'
  },
  subRowText:{
    fontSize: '0.75rem !important',
    wordBreak : 'break-all',
    wordWrap : 'break-word'
  },
  tabCell:{
    padding: '0.2rem 0.3rem 0.2rem !important'
  },
  tabHead:{
    padding: '0.1rem 0.3rem !important'
  },
  paper:{
    width:'50%',
    height:'75vh'
  }
}
export const ConfigureErrorStyle: IStyle = {
  title: {
    padding: '10px 0',
    color:'#ff9800',
    background: 'rgb( 238, 238, 238 )',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  listItem: {
    margin: '0 0 0 20px',
    listStyleType: 'disc'
  },
  header: {
    color:PhilipsBlue
  },
  footer: {
    background: 'rgb( 238, 238, 238 )',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  }
}
export const VariableSummaryStyle: IStyle = {
  root: {
    ...colFlex( 'flex-start' ),
    width: '100%',
    margin: '5px 0'
  },
  section: {
    ...rowFlex( 'flex-start' ),
    width: '100%',
  },
  content: {
    width: '100%',
    overflow: 'hidden'
  }
}
export const ConfigurationSummaryPanelStyle: IStyle = {
  root: {
    ...colFlex(),
    ...squareSize( '100%' ),
    overflow: 'auto',
    margin: '0 0 10px 10px',
    
  },
  accordion: {
    marginTop: '10px',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  content:{
    fontSize:'1rem',
    fontWeight:'800'
  }
}
export const SectionSummaryStyle: IStyle = {
  root: {
    marginTop: '10px',
    width: '100%'
  },
  firstLevel: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin:'0px'
  },
  multiLevel: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    margin:'0px'
  }
}

export const ConfigurationValidationDialogStyle: IStyle = {
  header: {
    background: PhilipsBlue,
    borderBottom: '0.1rem solid rgba(0, 0, 0, 0.12)',
    color:'white'
  },
  footer: {
    background: 'rgb( 238, 238, 238 )',
    borderTop: '0.1rem solid rgba(0, 0, 0, 0.12)'
  },
  message: {
    color : 'red',
    fontSize : '1rem !important',
    fontWeight : 'bold !important',
    padding : '0.8rem 0.5rem',
    overflow: 'hidden', 
    width: '100%', 
    textOverflow: 'ellipsis'
  },
  tableHeader:{
    fontSize: '1.3rem !important',
    fontWeight: 'bold !important'
  },
  rowText:{
    fontSize: '0.8rem !important',
    fontWeight: 'bold !important',
    wordBreak : 'break-all',
    wordWrap : 'break-word'
  },
  subTableHeader:{
    fontSize: '0.9rem !important',
    fontWeight: 'bold !important'
  },
  subRowText:{
    fontSize: '0.8rem !important',
    wordBreak : 'break-all',
    wordWrap : 'break-word'
  },
  removedSubRowText:{
    color:'red !important',
    fontSize: '0.8rem !important',
    wordBreak : 'break-all',
    wordWrap : 'break-word'
  },
  tabCell:{
    padding: '0.5rem !important'
  },
  paper:{
    width:'75%',
    height:'75vh'
  }
}

export const SalesTextDialogStyle: IStyle = {
  header: {
    background: PhilipsBlue,
    fontSize:'1.2rem !important',
    padding:'0.5rem !important',
    borderBottom: '0.1rem solid rgba(0, 0, 0, 0.12)',
    color:'white'
  },
  paper:{
    width:'50%',
    height:'75vh'
  },
  summary:{
    display:'grid !important',
    gridTemplateColumns:'40% 1fr'
  },
  shorten:{
    overflow:'hidden !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  } 
}
export const ProgresBarwithLabelStyle: IStyle = {
  root:
  {
    position: 'relative', 
    display: 'inline-flex'
  },
  content:{
    top: '2px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}
