
import { UserApi } from './UserApi';
import { RoleApi } from './RoleApi';
import { ApiKeyApi } from './ApiKeyApi';
import { ApplicationApi } from './ApplicationApi';
import { DataMaintenanceApi } from './DataMaintenanceApi';
import { ProductAssociationApi } from './ProductAssociationApi';
import { FeatureFlagApi } from './FeatureFlagApi';

import { serviceExtension } from '../store/AppStore';
import { userActions, roleActions, apikeyActions, applicationActions, dataMaintenanceActions, featureFlagActions } from '../store';
import { SalesTextApi } from './SalesTextApi';
import { PriceApi } from './PriceApi';
import { ProductHierarchyApi } from './ProductHierarchyApi';
import { LanguageApi } from './LanguageApi';
import { PriceListMapApi } from './PriceListMapApi';
import { MarketApi } from './MarketApi';


//registration of API calls on specified dispatch actions

serviceExtension.add( userActions.USERLIST, UserApi.getUsers );
serviceExtension.add( userActions.DELETEUSERS, UserApi.deleteUsers );
serviceExtension.add( userActions.CREATEUSER, UserApi.createUser );
serviceExtension.add( userActions.UPDATEUSER, UserApi.updateUser );
serviceExtension.add( userActions.UPDATEUSERROLEASSOCIATION, UserApi.updateUserRoleAssociation );
serviceExtension.add( userActions.EXPORTUSERDETAILS, UserApi.exportUserDetails );
serviceExtension.add( userActions.GETUSERDETAILS, UserApi.getUserDetails );
serviceExtension.add( userActions.GETLOGGEDINUSERDETAILS, UserApi.getLoggedInUserDetails );

serviceExtension.add( roleActions.ROLELIST, RoleApi.getRoles );
serviceExtension.add( roleActions.CREATEROLE, RoleApi.createRole );
serviceExtension.add( roleActions.UPDATEROLE, RoleApi.updateRole );
serviceExtension.add( roleActions.DELETEROLES, RoleApi.deleteRoles );
serviceExtension.add( roleActions.GETROLEDETAILS, RoleApi.getRoleDetails );

serviceExtension.add( apikeyActions.APIKEYLIST, ApiKeyApi.getApiKeys );
serviceExtension.add( apikeyActions.CREATEAPIKEY, ApiKeyApi.createApiKey );
serviceExtension.add( apikeyActions.UPDATEAPIKEY, ApiKeyApi.updateApiKey );
serviceExtension.add( apikeyActions.DELETEAPIKEYS, ApiKeyApi.deleteApiKeys );
serviceExtension.add( apikeyActions.GETACCESSTYPES, ApiKeyApi.getAccessTypes );
serviceExtension.add( apikeyActions.GETCHANNELS, ApiKeyApi.getChannels );
serviceExtension.add( apikeyActions.GETSERVICEUSERS, ApiKeyApi.getServiceUsers );
serviceExtension.add( apikeyActions.GETAPIKEYDETAILS, ApiKeyApi.getApiKeyDetails );
serviceExtension.add( apikeyActions.GETPROPERTYLIST, ApiKeyApi.getPropertyList );
serviceExtension.add( apikeyActions.GETPRIVILEGES, ApiKeyApi.getPrivileges );

serviceExtension.add( applicationActions.APPLICATIONLIST, ApplicationApi.getApplications );
serviceExtension.add( applicationActions.CREATEAPPLICATION, ApplicationApi.createApplication );
serviceExtension.add( applicationActions.UPDATEAPPLICATION, ApplicationApi.updateApplication );
serviceExtension.add( applicationActions.DELETEAPPLICATIONS, ApplicationApi.deleteApplications );
serviceExtension.add( applicationActions.GETAPPLICATIONDETAILS, ApplicationApi.getApplicationDetails );

serviceExtension.add( dataMaintenanceActions.IMPORTSALESTEXT, SalesTextApi.importSalesText );
serviceExtension.add( dataMaintenanceActions.GETSALESTEXT, SalesTextApi.getSalesText );

serviceExtension.add( dataMaintenanceActions.IMPORTPRICE, PriceApi.importPrice );
serviceExtension.add( dataMaintenanceActions.GETPRICE, PriceApi.getPrice );

serviceExtension.add( dataMaintenanceActions.IMPORTPRODUCTHIERARCHY, ProductHierarchyApi.importProductHierarchy );
serviceExtension.add( dataMaintenanceActions.GETPRODUCTHIERARCHY, ProductHierarchyApi.getProductHierarchy );
serviceExtension.add( dataMaintenanceActions.COMPAREPRODUCTHIERARCHY, ProductHierarchyApi.compareProductHierarchy );
serviceExtension.add( dataMaintenanceActions.PUBLISHPRODUCTHIERARCHY, ProductHierarchyApi.publishProductHierarchy);


serviceExtension.add( dataMaintenanceActions.GETLANGUAGES, LanguageApi.getLanguages );
serviceExtension.add( dataMaintenanceActions.DELETELANGUAGE, LanguageApi.deleteLanguage );
serviceExtension.add( dataMaintenanceActions.UPDATELANGUAGE, LanguageApi.updateLanguage );
serviceExtension.add( dataMaintenanceActions.CREATELANGUAGE, LanguageApi.createLanguage );
serviceExtension.add( dataMaintenanceActions.GETLANGUAGEASSOCIATION, LanguageApi.getLanguageAssociation );
serviceExtension.add( dataMaintenanceActions.GETLANGUAGEDETAILS, LanguageApi.getLanguageDetails );
serviceExtension.add( dataMaintenanceActions.UPDATELANGUAGEASSOCIATION, LanguageApi.updateLanguageAssociation );
serviceExtension.add( dataMaintenanceActions.DELETELANGUAGEASSOCIATION, LanguageApi.deleteLanguageAssociation );

serviceExtension.add( dataMaintenanceActions.GETPRICELISTMAP, PriceListMapApi.getPriceListMap );
serviceExtension.add( dataMaintenanceActions.GETPRICELISTMAPDETAILS, PriceListMapApi.getPriceListMapDetails );
serviceExtension.add( dataMaintenanceActions.UPDATEPRICELISTMAP, PriceListMapApi.updatePriceListMap );

serviceExtension.add( dataMaintenanceActions.GETCOUNTRYLIST, DataMaintenanceApi.getCountryList );
serviceExtension.add( dataMaintenanceActions.GETIMAGES, DataMaintenanceApi.getImages );

serviceExtension.add( dataMaintenanceActions.GETPRODUCTCATALOG, ProductAssociationApi.getProductCatalog );
serviceExtension.add( dataMaintenanceActions.GETPRODUCTCONFIGURATION, ProductAssociationApi.getProductConfiguration );

serviceExtension.add( dataMaintenanceActions.GETMARKETS, MarketApi.getMarkets );
serviceExtension.add( dataMaintenanceActions.GETMARKETDETAILS, MarketApi.getMarketDetails );
serviceExtension.add( dataMaintenanceActions.DELETEMARKET, MarketApi.deleteMarket );
serviceExtension.add( dataMaintenanceActions.UPDATEMARKET, MarketApi.updateMarket );
serviceExtension.add( dataMaintenanceActions.CREATEMARKET, MarketApi.createMarket );
serviceExtension.add( dataMaintenanceActions.GETMARKETASSOCIATION, MarketApi.getMarketAssociation );
serviceExtension.add( dataMaintenanceActions.UPDATEMARKETASSOCIATION, MarketApi.updateMarketAssociation );
serviceExtension.add( dataMaintenanceActions.DELETEMARKETASSOCIATION, MarketApi.deleteMarketAssociation );

serviceExtension.add( featureFlagActions.GETFEATUREFLAGS, FeatureFlagApi.getFeatureFlags );
serviceExtension.add( featureFlagActions.CREATEFEATUREFLAG, FeatureFlagApi.createFeatureFlag );
serviceExtension.add( featureFlagActions.UPDATEFEATUREFLAG, FeatureFlagApi.updateFeatureFlag );
serviceExtension.add( featureFlagActions.DELETEFEATUREFLAG, FeatureFlagApi.deleteFeatureFlags );
serviceExtension.add( featureFlagActions.GETFEATUREFLAGDETAILS, FeatureFlagApi.getFeatureFlagDetails );
serviceExtension.add( featureFlagActions.GETALLFEATUREFLAGS, FeatureFlagApi.getAllFeatureFlags );