import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertMessageDispatcher, alertMessageState, Combine, AppStore, featureFlagState, featureFlagDispatcher } from '../../store';
import { IFeatureFlagsManagementProps } from '../../../types';
import { displayMessage } from '../DisplayMessage';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../data/Constants';
import { getDelMsgForSelectedKeys } from '../../services/DataHelperFunctions';

export const $FeatureFlagsManagement = ( props: IFeatureFlagsManagementProps ) => {
  
  const {t} = useTranslation();

  const deleteItems = ( req )=>{
    const featureFlags = AppStore.getState().featureFlags.featureFlags;
    props.deleteFeatureFlags( props.token,req ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.featureFlags', { length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( {show:true ,message:message, type:'error'} )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.featureFlags', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getFeatureFlags( props.token, featureFlags.currentPage, featureFlags.recordsPerPage, featureFlags.searchKey );
      }
    } )
  }

  if( !props.featureFlags ) {
    return null;
  }
  
  if( props.error.code && props.error.type === EApiType.FeatureFlagsKey ) {
    return <h4>
      {displayMessage( props.error, t, '', props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.featureFlagsManagement' )}</h3>
    
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.FeatureFlags }
      getDataList = { props.getFeatureFlags }
      moduleType = { EModuleType.FeatureFlags }
      deleteItems={ deleteItems }
    />
  </>
}
  
export const FeatureFlagsManagement = connect( Combine( featureFlagState, alertMessageState ),Combine( featureFlagDispatcher, alertMessageDispatcher ) )( $FeatureFlagsManagement ) ;