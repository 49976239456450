import React from 'react';
import { Box, Grid } from '@mui/material';

export const DetailedView = ( objectData, header, t, displayName? ) => {
  if( objectData.length < 1 ) {
    return null;
  }
  return <>
    <Box className="manipulationBox pt-1">
      <Box className="manipulationForm">
        
        <fieldset className="inputFieldset w-100">
          <legend className="inputLegend" id="map">{t( 'labels.' + header ) }</legend>
          <Grid container spacing={ 2 } className="pt-1">
            {objectData.map( data=>{
              return <Grid item xs={ 4 } key={ data.code || data.name || data } className="pt-0">
                { data.code || data.name || data } { displayName && ' (' + data.name + ')' }
              </Grid>
            } )
            }
          </Grid>
        </fieldset>
      </Box>
    </Box>
  </>
}