import React from 'react';
import { UserPanel } from './UserPanel';
import { useAuth } from 'oidc-react';
import { Button } from '@mui/material';
import { authSettings } from '../api/OidcSettings';
import { useTranslation } from 'react-i18next';
import { userDispatcher } from '../store';
import { connect } from 'react-redux';
import { IHeaderLoginProps } from '../../types';

/**
 * Renders a button for login and logout functionality
 * @param {IHeaderLoginProps} props the props required by the component
 * @returns {JSX.Element} the user menu component
 */
export const $HeaderLogin = ( {setAuthState}:IHeaderLoginProps ) => {
  if ( authSettings.Developer_Mode ) {
    return <Button color="secondary">{'LOCAL DEVELOPER'}</Button>;
  }
  const {t} = useTranslation();
  const auth = useAuth();
  const isLoggedin = auth && auth.userData;
  const userName = `${auth.userData?.profile.name}`;

  return isLoggedin
    ? <UserPanel user={ userName } onLogout={ ()=>{
      setAuthState && setAuthState( 'logout' );
      auth.signOut();
    } }
    />
    : <Button color="secondary" onClick={ () => {
      setAuthState && setAuthState( 'login' );
      auth.signIn();
    } }
    >{t( 'button.login' )}</Button>;
};

export const HeaderLogin = connect( null,userDispatcher )( $HeaderLogin )