import { Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions, Grid, FormControlLabel, Checkbox, Box, Tab } from '@mui/material';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { dialogDispatcher,dialogState } from '../../store';
import { useTranslation, Trans } from 'react-i18next';
import { ECommonDialogType, ETabValue, EDataMaintenanceType, EDataTableType, foreignKeysForTable, EActionType, EModuleType, ECellType, ECellSubType } from '../../data/Constants';
import { LanguageAssociationManipulation } from '../Product/LanguageAssociationManipulation';
import { MarketAssociationManipulation } from '../Product/Market/MarketAssociationManipulation';
import { UserManipulation } from '../Administration/Users/UserManipulation';
import { RoleManipulation } from '../Administration/Roles/RoleManipulation';
import { ApiKeyManipulation } from '../Administration/ApiKeys/ApiKeyManipulation';
import { LanguageManipulation }from '../Product/LanguageManipulation';
import { MarketManipulation }from '../Product/Market/MarketManipulation';
import { PriceListMapManipulation } from '../DataMaintenance/PriceListMap/PriceListMapManipulation';
import { DetailedView } from './DetailedView';
import { useAuth } from 'oidc-react';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ApplicationManipulation } from '../Administration/Applications/ApplicationManipulation';
import { FeatureFlagManipulation } from '../FeatureFlags/FeatureFlagsManipulation';
import { ProductHierarchyCompare } from '../DataMaintenance/ProductHierarchy/ProductHierarchyCompare';
import DraggableComponent from '../DraggableComponent';
import { DetailsDialog } from './DetailsDialog';
import CancelIcon from '@mui/icons-material/Cancel';


const LongSalesTextContent = ( longSalesTextProps ) => {
  const { message,handleChange, TabValue, t } = longSalesTextProps;
  let longSaleText = null;
  try {
    longSaleText = message.longSalesText ? JSON.parse( message.longSalesText ) : message.longSalesText;
  } catch {
    longSaleText = message.longSalesText;
  }
  return <>
    <TabContext value={ TabValue || ETabValue.ShortSalesText }>
      <Box className="dialog-box">
        <TabList onChange={ handleChange } >
          <Tab className="dialog-tab text-capitalize"
            value={ ETabValue.ShortSalesText } label={ t( 'labels.shortSalesText' ) }
          />
          <Tab className="dialog-tab text-capitalize" 
            value={ ETabValue.LongSalesText } label={ t( 'labels.longSalesText' ) }
          />          
        </TabList>
      </Box>
      <TabPanel className="dialog-panel pt-1" value={ ETabValue.ShortSalesText } >
        { message.shortSalesText}
      </TabPanel>
      <TabPanel className="dialog-panel pt-1 " value={ ETabValue.LongSalesText }>
        { parse( DOMPurify.sanitize( longSaleText ) ) }
      </TabPanel>       
    </TabContext>
  </>;
}

const ApiKeyCreateSuccess = ( apiKeyCreateSuccessProps ) => {
  const { apiKey, disableButton, enableButton, t } = apiKeyCreateSuccessProps;
  const copyText = ( )=>{
    navigator.clipboard.writeText( apiKey );
  }
  return <>    
    <div className="d-flex align-center pb-1"><b>{t( 'labels.apiKey' )}: &nbsp;</b>
      <span className="apiKey" >{apiKey}</span>
      <IconButton aria-label="close" onClick={ ()=>copyText() }>
        <ContentCopyIcon />
      </IconButton>
    </div>
    <FormControlLabel
      value={ disableButton }
      checked={ !disableButton }
      control={ <Checkbox /> }
      label={ t( 'labels.copyApiKey' ) }
      labelPlacement="end"
      onChange={ enableButton }
      name="copyKey"
    />
    <br/>
    <b>{t( 'labels.note' )}: </b>{t( 'messages.copyApiKeyMessage' )}
  </>
}

function getHeader( manipulationType:string,t:( arg: string, arg2?: object ) => string,type:string,name:string ) {
  if( manipulationType === EActionType.Delete ) {
    return <Grid container alignItems="center">
      <><WarningIcon className="warningIcon"/> &nbsp;</> 
      <span > {t( 'labels.deleteConfirm' )} </span>
    </Grid> 
  }else{
    return manipulationType === EActionType.Create ? t( 'labels.create' ) + ' ' + t( 'header.' + type ) : t( 'labels.edit' ) + ' ' + t( 'header.' + type ) + ' (' + name + ')';
  }
}

//update the method for reduce cognitive complexities
const getDialogTitle = ( props,t )=>{
  switch( props.dialogData.type ) {
    case ECommonDialogType.ApiKeyCreateSuccess:
      return <Grid container alignItems="center">
        <><CheckCircleIcon className="successIcon"/> &nbsp;</> 
        <span >  {t( 'dialogBox.success' )}</span>
      </Grid>
    case EDataMaintenanceType.PriceListMap:
      return `${t( 'labels.editPriceListMap' )} (${props.dialogData.message.country})`;
    case EDataMaintenanceType.LanguageAssociation:
      return `${t( 'labels.editLanguageAssociation' )} (${props.dialogData.message.productCode})`;
    case EDataMaintenanceType.MarketAssociation:
      return `${t( 'labels.editMarketAssociation' )} (${props.dialogData.message.productCode})`;
    case ECommonDialogType.Image:
      return `${t( 'labels.image' )} (${props.dialogData.message.name})`;
    case ECommonDialogType.LongSalesText:
      return `${t( 'header.salesText' )} (${props.dialogData.message.materialCode})`;
    case ECommonDialogType.ShortSalesText:
      return `${t( 'header.salesText' )} (${props.dialogData.message.materialCode})`;
    case ECommonDialogType.Languages:
      return getHeader( props.dialogData.manipulationType,t, props.dialogData.type,props.dialogData.message.name );
    case ECommonDialogType.Markets:
      return getHeader( props.dialogData.manipulationType,t, props.dialogData.type,props.dialogData.message.name );
    case ECommonDialogType.Error:
      return <><CancelIcon className="errorIcon"/>&nbsp;{t( 'dialogBox.error' )}</>;
    default:
      return getDialogTitleByManipulationType( props,t );
  }
}

const getDialogTitleByManipulationType = ( props,t )=>{
  switch( props.dialogData.manipulationType ) {
    case EActionType.Compare:
      return `${t( 'labels.compare' )}`;
    case EActionType.View:
      return '' + t( 'header.' + props.dialogData.header.detailedViewHeader ) + ' (' + props.dialogData.message[props.dialogData.uniqueKey] + ')';
    default:
      return getDialogTitleByModuleType( props,t );
  }
}

const getDialogTitleByModuleType = ( props, t ) => {
  switch( props.dialogData.moduleType ) {
    case EModuleType.Administration:
      return getHeader( props.dialogData.manipulationType,t, props.dialogData.type,props.dialogData.message.name );
    case EModuleType.FeatureFlags:
      return getHeader( props.dialogData.manipulationType,t, props.dialogData.type,props.dialogData.message.flagName );
    default:
      return 'dialog';
  }
}


const getDialogActions = ( props, closeDialog, disableButton, t )=>{
  if( props.dialogData.type === ECommonDialogType.ApiKeyCreateSuccess ) {
    return <Button onClick={ closeDialog } disabled={ disableButton } >{t( 'button.ok' )}</Button>
  }else if( props.dialogData.type === ECommonDialogType.EditLanguageAssociation ||
            props.dialogData.type === ECommonDialogType.EditMarketAssociation || 
            props.dialogData.type === ECommonDialogType.EditPriceListMap || 
            props.dialogData.type === EDataMaintenanceType.ProductHierarchy ) {
    return null
  }else if( props.dialogData.manipulationType === EActionType.Delete ) {
    return <><Button className="text-capitalize" onClick={ closeDialog }>{t( 'button.cancel' )}</Button>
      <Button onClick={ props.dialogData.callDeleteAPI } >{t( 'button.ok' )}</Button></>
  }else{
    return <Button onClick={ closeDialog } >{t( 'button.ok' )}</Button>;
  }
}

const CommonDialogContent = ( commonDialogContentProps ) => {
  const { props, token, uniqueKey, t, handleChange, TabValue, disableButton, enableButton } = commonDialogContentProps;
  if( props.dialogData.manipulationType === EActionType.Delete ) {
    const items = props.dialogData.message.selectedIds.join( ', ' );
    const message = t( 'messages.deleteConfirmation' );
    if( props.dialogData.type === EDataTableType.Applications ) {
      return <Trans i18nKey={ 'messages.confirmation.delete.applications' } values={ { applicationNames:items } } />
    } else if( props.dialogData.type === EDataTableType.FeatureFlags ) {
      return <Trans i18nKey={ 'messages.confirmation.delete.featureFlags' } values={ { featureFlagNames:items } } />
    } else{
      return <div className="pt-1" >{ message }</div>;
    }
  } else{
    switch( props.dialogData.type ) {        
      case EDataTableType.Users:
        return <UserManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>;
      case EDataTableType.Roles:
        return <RoleManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>;
      case EDataTableType.ApiKeys:
        return <ApiKeyManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>;
      case EDataTableType.Applications:
        return <ApplicationManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>;
      case EDataTableType.PriceListMap :
        return <PriceListMapManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>
      case EDataTableType.Markets :
        return <MarketManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>
      case EDataTableType.MarketAssociation :
        return <MarketAssociationManipulation data={ props.dialogData.message } closeDialog={ props.resetDialogMessage }/>
      case EDataTableType.Languages :
        return <LanguageManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>
      case EDataTableType.LanguageAssociation :
        return <LanguageAssociationManipulation data={ props.dialogData.message } closeDialog={ props.resetDialogMessage }/>
      case EDataTableType.FeatureFlags:
        return <FeatureFlagManipulation token={ token } id={ props.dialogData.message[uniqueKey] } type={ props.dialogData.manipulationType } closeDialog={ props.resetDialogMessage }/>;
      case ECommonDialogType.LongSalesText :
        return <LongSalesTextContent message={ props.dialogData.message } TabValue={ TabValue } handleChange={ handleChange }t={ t }/>
      case ECommonDialogType.Image:
        return <div className="text-center"><img src={ props.dialogData.message.url }/></div>;
      case ECommonDialogType.ApiKeyCreateSuccess:
        return <ApiKeyCreateSuccess apiKey={ props.dialogData.message } enableButton={ enableButton } disableButton={ disableButton } t={ t }/>
      case EDataMaintenanceType.ProductHierarchy:
        return <ProductHierarchyCompare token={ token } closeDialog={ props.resetDialogMessage } />;
      case ECommonDialogType.Error:
        return <div className="pt-1">{props.dialogData.message}</div>;
      default:
        return <div className="pt-1" >Default div</div>;
    }
  }
}

const getDialogContent = ( props, disableButton, enableButton, t, handleChange, TabValue,token )=>{  
  const uniqueKey = foreignKeysForTable[props.dialogData.type];
  if( props.dialogData.type === ECellType.ArrayChips ) {
    return DetailedView( props.dialogData.message.channels, props.dialogData.header.field, t )
  } else if( props.dialogData.type === ECellType.ObjectChips ) {
    switch ( props.dialogData.header.subType ) {
      case ECellSubType.Roles:
        return DetailedView( props.dialogData.message.roles, props.dialogData.header.field, t )
      case ECellSubType.Countires:
        return DetailedView( props.dialogData.message.countries, props.dialogData.header.field, t, true )
      case ECellSubType.Languages:
        return DetailedView( props.dialogData.message.languages, props.dialogData.header.field, t, true )        
    }
  } else if( props.dialogData.type === ECellType.Details ) {
    switch ( props.dialogData.header.subType ) {
      case ECellSubType.AssociatedMarkets:
        return DetailsDialog( props.dialogData.message.markets, props.dialogData.detailsViewType, t, true )
      case ECellSubType.AssociatedLanguages:
        return DetailsDialog( props.dialogData.message.languages, props.dialogData.detailsViewType, t, true )  
      case ECellSubType.Images:
        return DetailsDialog( props.dialogData.message.materialImages, props.dialogData.detailsViewType, t, true )  
      
    }
  } else{
    return CommonDialogContent( { props, token, uniqueKey, t, handleChange, TabValue, disableButton, enableButton } );
  }
}

const largerDialog = ( props ) => {
  return props.dialogData.manipulationType === EActionType.View && props.dialogData.type !== ECellType.Details || 
                                              ( props.dialogData.type === EDataTableType.ApiKeys || 
                                                props.dialogData.type === EDataTableType.FeatureFlags || 
                                                props.dialogData.type === EDataTableType.MarketAssociation || 
                                                props.dialogData.type === EDataTableType.LanguageAssociation || 
                                                props.dialogData.type === EDataTableType.PriceListMap || 
                                                props.dialogData.type === EDataMaintenanceType.ProductHierarchy ) && 
                                                ( props.dialogData.manipulationType === EActionType.Compare || 
                                                  props.dialogData.manipulationType === EActionType.Create || 
                                                  props.dialogData.manipulationType === EActionType.Edit );
}

const xLargerDialog = ( props ) => {
  return props.dialogData.manipulationType === EActionType.View && props.dialogData.type === ECellType.Details;
}

export const $CommonDialog = ( props ) => {
  const auth = useAuth();
  const token = auth?.userData?.access_token;
  const [ disableButton, setDisableButton ] = useState( true );
  const [ TabValue, setTabValue ] = useState<string|null>( 'ShortSalesText' );
  const {t} = useTranslation();

  const handleChange = ( ...params:[React.SyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  useEffect( ()=>{
    if( props.dialogData.type === ECommonDialogType.LongSalesText || props.dialogData.type === ECommonDialogType.ShortSalesText ) {
      setTabValue( props.dialogData.type );
    }
  } ,[props.dialogData.type] )

  if( !props.dialogData.show ) {
    return null;
  }
 
  const closeDialog = ()=>{
    setDisableButton( true );
    props.resetDialogMessage( );
  }

  const enableButton = ( event )=>{
    setDisableButton( !event.target.checked );
  }

  return <Dialog open={ props.dialogData.show } 
    aria-labelledby="draggable-dialog-title" 
    PaperComponent={ DraggableComponent } 
    className={ `common-dialog-style success-dialog 
    ${props.dialogData.type !== ECommonDialogType.ApiKeyCreateSuccess 
      && props.dialogData.manipulationType !== EActionType.Delete
      && props.dialogData.manipulationType !== ECommonDialogType.Error ? 'manipulationDialog' : ''} 
      ${ largerDialog( props ) ? 'largerManipulationDialog' : ''} 
      ${ xLargerDialog( props ) ? 'xLargerDialog' : ''}` }
  >

    <DialogTitle className="header">
      {getDialogTitle( props,t )}
    </DialogTitle>

    <DialogContent className="pb-0 align-center ">
      {getDialogContent( props,disableButton,enableButton,t,handleChange,TabValue,token )}
    </DialogContent>

    <DialogActions >
      {getDialogActions( props,closeDialog,disableButton,t )}
    </DialogActions>

  </Dialog> 
}

export const CommonDialog = connect( dialogState,dialogDispatcher )( $CommonDialog );