import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertMessageDispatcher, alertMessageState, Combine, userDispatcher, userState, AppStore } from '../../../store';
import { IUserManagementProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { getDelMsgForSelectedKeys } from '../../../services/DataHelperFunctions';

export const $UserManagement = ( props:IUserManagementProps ) => {
  
  const {t} = useTranslation();

  const deleteItems = ( req )=>{
    const users = AppStore.getState().administartion.users;
    props.deleteUsers( props.token,req ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.users',{ length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( {show:true, message:message, type:'error'} )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.users', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getUsers( props.token, users.currentPage, users.recordsPerPage, users.searchKey );
      }
    } )
  }

  if( !props.users ) {
    return null;
  }
  
  if( props.error.code && props.error.type === EApiType.UserKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.userManagement' )}</h3>
    
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Users }
      getDataList = { props.getUsers }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}
  
export const UserManagement = connect( Combine( userState, alertMessageState ),Combine( userDispatcher,alertMessageDispatcher ) )( $UserManagement ) ;