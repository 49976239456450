import React, { useEffect,useState } from 'react';
import { Trans,useTranslation } from 'react-i18next';
import { IBrowserDetails } from '../../types';
import { LocalStore } from '../services/LocalStore';
import {Dialog, DialogActions,Button, DialogContent,DialogContentText, DialogTitle, Checkbox, FormControlLabel } from '@mui/material';
import { isChrome, isEdge, isSafari } from '../services/DetectBrowser';
import { ELocalStore } from '../data/Constants';
import DraggableComponent from './DraggableComponent';


const browserDetails:IBrowserDetails[] = [
  {
    name: 'Chrome',
    version: 100,
    image:'../../public/png/chrome.png'
  },
  {
    name: 'Edge',
    version: 100,
    image:'../../public/png/edge.png'
  },
  {
    name: 'Safari',
    version: 14,
    image:'../../public/png/safari.png'
  }
]

const versionImages = ( ) =>{
  return <>{ browserDetails.map( ( browser ) => 
    <div className="browserBox" key={ browser.name }>
      <img src={ browser.image }/>
        <span>{ browser.name }</span>
    </div>
  )}
  </>
}

export const $UnsupportedBrowser = ( ) => {
  const { t } = useTranslation();
  const [isChecked, setCheckBox] = useState(false);
  const [showPopup,setShowPopup]= useState(false);

  //get the user acknowledgement for unsupport browser from local storage
  let isUnsBrowAck = LocalStore.get( ELocalStore.IsUnSupportedBrowserAck );
  isUnsBrowAck = Boolean(isUnsBrowAck) && typeof isUnsBrowAck !== 'string';
 
  useEffect(()=>{
    if(!( isChrome() || isEdge() || isSafari()) && !isUnsBrowAck){
      setShowPopup(true);
    }
  },[] );

  const handleClose = () => {
    //set to IsUnsBrowAck value to true if user check the check box in popupmesaage
    if(isChecked){
      LocalStore.set( ELocalStore.IsUnSupportedBrowserAck, true );
    }
    setShowPopup(false);
  };

  const handleChange=()=>{
    setCheckBox(!isChecked);
  }
  return (
      <Dialog  open={showPopup } aria-labelledby="draggable-dialog-title"  className='common-dialog-style unsupported-browser cursor-move' PaperComponent={ DraggableComponent } >
        <DialogTitle className="header">
          {t( 'messages.browserNotSupported' )}
        </DialogTitle>
        <DialogContent className="message pb-0">
            <DialogContentText id="alert-dialog-description">
            <Trans i18nKey="messages.browserNotSupportedMessage" components={{ 1: <br /> }} />
            </DialogContentText>
            <div className="versionSupported">
              {t( 'messages.supportedBrowsers' )}
              
              {versionImages( )}
            </div>
        </DialogContent>
        <DialogActions sx={{justifyContent:'flex-start'}} >
        <div style={{display:'flex',width:'100%'}}>
          <div  style={{textAlign:'start',flexBasis:'90%'}}>
          <FormControlLabel
            checked={isChecked}
            control={ <Checkbox /> }
            label="Don't display this message again"
            labelPlacement="end"
            onChange={ (  )=>{
              handleChange( )
            } }
            name="isDefault"
          />
          </div>
          <div style={{justifyContent:'flex-end',}}>
          <Button className="text-capitalize" color="primary" variant="contained" onClick={handleClose} >
              {t('button.ok')}
            </Button>
          </div>    
        </div>
        </DialogActions>
    </Dialog>
  )
}

export const UnsupportedBrowser = $UnsupportedBrowser ;