import { Dispatch } from 'react';
import { IAlertMessage, IAlertMessageAction, IState } from '../../../types';

export const AlertMessageActions = {
  SET: 'alert-SET',
  RESET: 'alert-RESET'
}
  
const defaultAlertMessage = {
  show:false,
  message:'',
  type:null
} as IAlertMessage
  
export const alertMessageDispatcher = ( dispatch: Dispatch<IAlertMessageAction> ) => ( {
  setAlertMessage: ( alertMessageObj:IAlertMessage ) => dispatch( { type: AlertMessageActions.SET, alertMessage:alertMessageObj } ),
  resetAlertMessage: () => dispatch( { type: AlertMessageActions.RESET } ),
} )
  
export const alertMessageReducer = ( state = defaultAlertMessage, action: IAlertMessageAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case AlertMessageActions.SET:
      newState = { ...state, ...action.alertMessage };
      return newState;
    case AlertMessageActions.RESET:
      newState = { ...state, ...defaultAlertMessage };
      return newState;
    default:
      return state;
  }
};
  
export const alertMessageState = ( state: IState ) => ( { alertMessage: state.alertMessage } );