import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box,Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Collapse, Alert, AlertTitle, Autocomplete, InputAdornment } from '@mui/material';
import { AppStore, alertMessageState, alertMessageDispatcher, Combine, errorState, errorDispatcher, dataMaintainanceState, dataMaintainanceDispatcher } from '../../../store';
import { EActionType,EAction } from '../../../data/Constants';
import { displayMessage } from '../../DisplayMessage';
import { useAuth } from 'oidc-react';
import SearchIcon from '@mui/icons-material/Search';

function handleApiResponse( res, props, failMessage:string, successMessage:string ) {
  const priceListMap = AppStore.getState().dataMaintainance.priceListMap;
  if( res.error ) {
    const message = res.error.message ? res.error.message : failMessage
    props.setAlertMessage( {show:true, message:message, type:'error'} )
  }else{
    props.getPriceListMap( props.token, priceListMap.currentPage, priceListMap.recordsPerPage, priceListMap.searchKey )
    props.setAlertMessage( {show:true,message:successMessage,type:'success'} )
    props.closeDialog( false )
  }
}

function callPriceListMapApi( props, formData, t:( message:string ) => void ) {
  props.updatePriceListMap( props.token, {priceMaps:[formData]} ).then( res =>{
    handleApiResponse( res, props, t( 'messages.fail.update' ), t( 'messages.success.update' ) );
  } )
}

const loadFormData = async ( props, setInitialPriceMapData, setFormData, setCountryList, setFilteredCountryList ) => {
  const countryList = await props.getCountryList( props.token );
  let priceListMap = await props.getPriceListMapDetails( props.token, props.id );
  if( !priceListMap?.data?.priceMaps[0] ) {
    return null;
  }
  priceListMap = priceListMap?.data?.priceMaps[0];
  setCountryList( countryList.data );
  setFilteredCountryList( countryList.data );
  setInitialPriceMapData( priceListMap );
  setFormData( priceListMap );
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;
  const value = event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

const handleSearch = ( props ) =>{
  const { countryList, searchVal, setFilteredCountryList } = props;
  const filteredData = countryList.filter( country => country.name.toLowerCase().includes( searchVal.toLowerCase() ) || country.code.toLowerCase().includes( searchVal.toLowerCase() ) )
  setFilteredCountryList( filteredData )
}

const SearchBar = ( props )=>{
  const { countryList, searchValue, filteredCountryList, setSearchValue, setFilteredCountryList } = props
  return <div className="priceListMapSearchBar">
    <Autocomplete
      id="country-search-box"
      clearOnBlur
      freeSolo
      value = { searchValue }
      options={ [] }
      className="search-bar"
      renderInput={ ( params ) => <TextField { ...params } className="search-inner-text" placeholder={ 'Search' } InputProps={ { ...params.InputProps,
        startAdornment:  <InputAdornment position="end"> <SearchIcon />
        </InputAdornment>
      } }
             
      /> }
      onChange={ ( _,newVal )=>{
        const searchVal = newVal ? newVal : '';
        setSearchValue( searchVal );
        handleSearch( { countryList, searchVal, setFilteredCountryList } );
      } }
    />
    {searchValue ? <div className="records">{'Records: ' + filteredCountryList.length}</div> : null}
  </div>
}

export const $PriceListMapManipulation = ( props ) => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [formData, setFormData] = useState( {'country':'','map':''} );
  const [initialPriceMapData, setInitialPriceMapData] = useState( {'country':'','map':''} );
  const [searchValue, setSearchValue] = useState( '' );
  const [countryList, setCountryList] = useState( [] );
  const [filteredCountryList, setFilteredCountryList] = useState( [] );
  const [disableButton, setDisableButton] = useState( true );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );
  const manipulationType = props.type; //To get the type of manipulation being performed
 
  useEffect( ()=>{
    const detailsUpdated = JSON.stringify( initialPriceMapData ) !== JSON.stringify( formData )
    setDisableButton( !detailsUpdated )
  } )
  
  useEffect( ()=>{
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialPriceMapData, setFormData, setCountryList, setFilteredCountryList )
    }
  },[] )

  useEffect( () => {     
    if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
      setDisplayAlertMessage( true );
    }    
  }, [props.error] ) 

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    props.resetAlertMessage( );
    props.resetError();
    setDisplayAlertMessage( false );
  };

  const handleSubmit = ( event ) => {
    event.preventDefault();
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false );
    callPriceListMapApi( props, formData, t )
  }

  const handleCancel = ( ) =>{
    props.closeDialog( false );
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false ); 
  }

  return <>
    <Box className="manipulationBox pt-1">     
      <Box className="manipulationForm">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ handleClose }>
            <AlertTitle>{displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
          </Alert>
        </Collapse>
        <TextField name="country" label={ t( 'labels.country' ) } disabled variant="outlined" size="small" fullWidth value={ formData.country }/>
        <br/><br/>
        <TextField name="map" label={ t( 'labels.map' ) } disabled variant="outlined" size="small" fullWidth value={ initialPriceMapData.map }/>
        <br/><br/>
        <fieldset className="inputFieldset w-100">
          <legend className="inputLegend" id="map">{t( 'labels.newMap' ) }</legend>
          {SearchBar( { countryList, searchValue, filteredCountryList, setSearchValue, setFilteredCountryList } )}
          {countryList.length < 1 ? <p>{ t( 'messages.noCountriesFound' )}</p> :
            <RadioGroup
              aria-labelledby="map"
              name="map"
              value={ formData.map } 
              onChange={ ( e ) => {
                handleChange( e, setFormData )
              } }
            > <Grid container spacing={ 2 } className="pt-1">
                {filteredCountryList.map( ( value:string ) => {
                  return <Grid item xs={ 4 } key={ value.code } className="pt-0"> <FormControlLabel value={ value.code } label={ value.code + ' (' + value.name + ')' } control={ < Radio /> }/></Grid>
                } )
                }
              </Grid>
            </RadioGroup>}
        </fieldset>
        <Box className="formActionButtons">
          <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
            { t( 'button.save' ) }
          </Button>
          <Button size="medium" onClick={ handleCancel } className="text-capitalize"> { t( 'button.cancel' ) } </Button>
        </Box>
      </Box>
    </Box>
  </>
}
  
export const PriceListMapManipulation = connect( Combine( dataMaintainanceState, alertMessageState, errorState ),Combine( dataMaintainanceDispatcher, alertMessageDispatcher, errorDispatcher ) ) ( $PriceListMapManipulation ) ;