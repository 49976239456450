import React from 'react';
import { Button,Box } from '@mui/material';
import { connect } from 'react-redux';
import { alertMessageDispatcher, alertMessageState, Combine, dataMaintainanceState,userState,dataMaintainanceDispatcher, busyState, busyDispatcher } from '../../store';
import { useTranslation,Trans } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';
import { initDropZone } from '../../components/Dropzone';
import { EDataMaintenanceType, EImportStatus } from '../../data/Constants';

export const $FileUpload = ( props ) => {
  const propFileState = props.dataMaintainance.uploadFile;
  const {t} = useTranslation();

  const handleCloseImport = ()=>{
    props.removeUploadFile();//remove upload file after click cancel button
  }

  const handleUploadClick = () => {
    propFileState.importDropzone.processQueue();//trigger the file upload to the API using dropzone while click upload button
  };

  if( !props.users ) {
    return null;
  }

  const handleClickImport = ()=>{
    if( propFileState.importDropzone != null ) { // remove existing dropzone if any
      propFileState.importDropzone.destroy();
    }
    const dropZone = initDropZone( props,showAlertMessage );//initialize the dropzone to get the files from folder while click import button
    props.setUploadFile( {},dropZone )
    dropZone?.hiddenFileInput?.click();
  }

  const showAlertMessage = ( type:string,fileType:string, fileSize:number )=>{ //show alert message while import or upload file
    switch ( type ) {
      case EImportStatus.Success:
        props.setAlertMessage( {show:true,message:props.type === EDataMaintenanceType.ProductHierarchy ? <Trans i18nKey="messages.success.upload.productHierarchy" components={ { 1: <br /> } } /> : t( 'messages.success.upload.general' ),type:'success'} );
        break;
      case EImportStatus.InvalidType:
        props.setAlertMessage( { show:true, message:t( 'messages.fail.fileFormat',{format: fileType} ), type:EImportStatus.Error} );
        break;
      case EImportStatus.InvalidSize:
        props.setAlertMessage( { show:true, message:t( 'messages.fail.fileSize',{size:fileSize} ), type:EImportStatus.Error} );
        break;
      case EImportStatus.Error:
        props.setAlertMessage( {show:true,message:t( 'messages.fail.upload' ), type:EImportStatus.Error} )
        break;
    }
  }

  return <>
    <Box className="file-upload-box align-center">
      {!propFileState?.file?.name && <><Button onClick={ ()=>handleClickImport() } id="dropzone-upload" className="text-capitalize common-btn" variant="contained" startIcon={ <UploadIcon /> }>
        {t( 'button.import' )}
      </Button>
      </>}
      {propFileState?.file.name && <>
        <span className="span-overflow">{propFileState.file?.name}</span> &emsp;
        <><Button className="text-capitalize common-btn" variant="contained" color="error" onClick={ handleCloseImport } >
          {t( 'button.cancel' )}
        </Button> &emsp;</>
        <><Button className="text-capitalize common-btn" variant="contained" onClick={ handleUploadClick }>
          {t( 'button.upload' )}
        </Button></>
      </>}
    </Box>
  </>
}
  
export const FileUpload = connect( Combine( userState, dataMaintainanceState, alertMessageState, busyState ), Combine( alertMessageDispatcher, dataMaintainanceDispatcher, busyDispatcher ) )( $FileUpload ) ;
