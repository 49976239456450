import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { roleDispatcher, roleState, alertMessageState, alertMessageDispatcher, Combine, AppStore } from '../../../store';
import { IRoleManagementProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage'; 
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { getDelMsgForSelectedKeys } from '../../../services/DataHelperFunctions';

export const $RoleManagement = ( props:IRoleManagementProps ) => {
  
  if( !props.roles ) {
    return null;
  }
  
  const {t} = useTranslation();

  const deleteItems = ( req )=>{
    const roles = AppStore.getState().administartion.roles;
    props.deleteRoles( props.token,req ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.roles', { length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( {show:true, message:message, type:'error'} )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.roles', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getRoles( props.token, roles.currentPage, roles.recordsPerPage, roles.searchKey );
      }
    } )
  }

  if( props.error.code && props.error.type === EApiType.RoleKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.roleManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Roles }
      getDataList = { props.getRoles }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}
  
export const RoleManagement = connect( Combine( roleState,alertMessageState ),Combine( roleDispatcher,alertMessageDispatcher ) ) ( $RoleManagement ) ;