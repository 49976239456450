import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertMessageDispatcher, alertMessageState, Combine, applicationDispatcher, applicationState, AppStore } from '../../../store';
import { IApplicationManagementProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { getDelMsgForSelectedKeys } from '../../../services/DataHelperFunctions';

export const $ApplicationManagement = ( props:IApplicationManagementProps ) => {
  
  const {t} = useTranslation();

  const deleteItems = ( req )=>{
    const applications = AppStore.getState().administartion.applications;
    props.deleteApplications( props.token,req ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.applications', { length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( { show:true, message:message, type:'error' } )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.applications', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getApplications( props.token, applications.currentPage, applications.recordsPerPage, applications.searchKey );
      }
    } )
  }

  if( !props.applications ) {
    return null;
  }
  
  if( props.error.code && props.error.type === EApiType.ApplicationKey ) {
    return <h4>
      { displayMessage( props.error, t, '', props.auth ) }
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.applicationManagement' )}</h3>
    
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Applications }
      getDataList = { props.getApplications }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}
  
export const ApplicationManagement = connect( Combine( applicationState, alertMessageState ),Combine( applicationDispatcher, alertMessageDispatcher ) )( $ApplicationManagement ) ;