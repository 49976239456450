import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dataMaintainanceDispatcher, dataMaintainanceState, alertMessageState, alertMessageDispatcher, Combine, AppStore } from '../../store';
import { EApiType, EDataTableType, EModuleType } from '../../data/Constants';
import { MaintenanceTable } from '../Layouts/DataTable';
import { displayMessage } from '../DisplayMessage';
import { getDelMsgForSelectedKeys } from '../../services/DataHelperFunctions';

export const $LanguageManagement = ( props ) => {
  const {t} = useTranslation();

  const deleteItems = ( req )=>{
    const languages = AppStore.getState().dataMaintainance.languages;
    props.deleteLanguage( props.token,req ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.languages', { length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( {show:true, message:message, type:'error'} )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.languages', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getLanguages( props.token, languages.currentPage, languages.recordsPerPage, languages.searchKey );
      }
    } )
  }

  if( props.error.code && props.error.type === EApiType.LanguageKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <><h3 className="mainHeader"> {t( 'labels.languageManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Languages }
      getDataList = { props.getLanguages }
      moduleType = { EModuleType.Datamaintainance }
      deleteItems={ deleteItems }
    /></>
}

export const LanguageManagement = connect( Combine( dataMaintainanceState,alertMessageState ),Combine( dataMaintainanceDispatcher,alertMessageDispatcher ) ) ( $LanguageManagement ) ;