import { dataMaintenanceActions } from '../store';
import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';

function handleSuccess( response ) {
  return {
    data: response.data
  } 
}

export const PriceListMapApi = {
  getPriceListMap: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    const queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    return AxiosClient
      .post( `/price/v1/pricelist/read?${queryParams}`,
        {criteria:action.searchCode ? action.searchCode : ''},
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: dataMaintenanceActions.SETPRICELISTMAP,
          data: response.data.priceMaps,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            type: dataMaintenanceActions.SETPRICELISTMAP,
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.PriceListMapKey );
      } )
  },
    
  getPriceListMapDetails: ( action: { token: string, searchCode: string } ) => {
    return AxiosClient
      .post(
        '/price/v1/pricelist/read',
        {criteria:action.searchCode ? action.searchCode : ''},
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
  
  updatePriceListMap:( action:{token: string, request } )=>{
    return AxiosClient
      .post( '/price/v1/pricelist/create',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Update );
      } )
  }
}