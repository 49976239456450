import { Dispatch } from 'react';
import { IError, IErrorAction, IState } from '../../../types';

export const ErrorActions = {
  SET: 'error-SET',
  RESET: 'error-RESET',
  CONFIGURE:'error-CONFIGURE'
}

const defaultError = {
  code:null,
  message:null,
  page:null
} as IError

export const errorDispatcher = ( dispatch: Dispatch<IErrorAction> ) => ( {
  setError: ( errorObject:IError ) => dispatch( { type: ErrorActions.SET, error:errorObject } ),
  resetError: () => dispatch( { type: ErrorActions.RESET } ),
} )

export const errorReducer = ( state = defaultError, action: IErrorAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case ErrorActions.SET:
      newState = { ...state, ...action.error };
      return newState;
    case ErrorActions.RESET:
      newState = { ...state, ...defaultError };
      return newState;
    default:
      return state;
  }
};

export const errorState = ( state: IState ) => ( { error: state.error } );