import { Dispatch } from 'react';
import { IState, IFeatureFlagAction, IFeatureFlag } from '../../../types';
import { EDataTableType } from '../../data/Constants';
import { updateMaintenanceData, updateMaintenanceSearchData } from '../../services/DataHelperFunctions';

export const featureFlagActions = {
  GETFEATUREFLAGS: 'featureFlagActions-GETFEATUREFLAGS',  
  SETFEATUREFLAGS: 'featureFlagActions-SETFEATUREFLAGS',
  CREATEFEATUREFLAG: 'featureFlagActions-CREATEFEATUREFLAG',
  UPDATEFEATUREFLAG: 'featureFlagActions-UPDATEFEATUREFLAG',
  DELETEFEATUREFLAG: 'featureFlagActions-DELETEFEATUREFLAG',
  GETFEATUREFLAGDETAILS: 'featureFlagActions-GETFEATUREFLAGDETAILS',
  GETALLFEATUREFLAGS: 'featureFlagActions-GETALLFEATUREFLAGS'
}

const reducerActionMapping = {
  [featureFlagActions.SETFEATUREFLAGS]: EDataTableType.FeatureFlags
}

const initialState = {
  data: [],
  featureFlags: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  }
}

export const featureFlagDispatcher = ( dispatch: Dispatch<IFeatureFlagAction> ) => ( {
  getFeatureFlags:( token:string, page:number, limit:number, searchCode?:string )=>dispatch( { type: 'featureFlagActions-GETFEATUREFLAGS',token, page, limit, searchCode } ),
  createFeatureFlag:( token: string, request:IFeatureFlag )=>dispatch( { type: 'featureFlagActions-CREATEFEATUREFLAG',token,request } ),
  updateFeatureFlag:( token: string, request:IFeatureFlag )=>dispatch( { type: 'featureFlagActions-UPDATEFEATUREFLAG',token,request } ),
  deleteFeatureFlags:( token: string, request:IFeatureFlag )=>dispatch( { type: 'featureFlagActions-DELETEFEATUREFLAG',token,request } ),
  getFeatureFlagDetails:( token: string, request:string[] )=>dispatch( { type: 'featureFlagActions-GETFEATUREFLAGDETAILS',token,request } ),
  getAllFeatureFlags:( token: string )=>dispatch( { type: 'featureFlagActions-GETALLFEATUREFLAGS',token } ),
} )

export const featureFlagReducer = ( state = initialState, action:IFeatureFlagAction = { type: '' } ) => {
  if( Object.keys( reducerActionMapping ).includes( action.type ) ) {
    if( !action.searchKey ) {
      return updateMaintenanceData( state, action, reducerActionMapping[action.type] )
    } else {
      return updateMaintenanceSearchData( state, action, reducerActionMapping[action.type] )
    }
  }
  return state;
}
export const featureFlagState = ( state: IState ) => ( { featureFlags: state.featureFlags } );