import Dropzone from 'dropzone';
import { appSettings } from '../settings';
import 'dropzone/dist/dropzone.css';
import { EDataMaintenanceType, EFileFormat, EFileSizeBytes, EFileSizeMB, EImportStatus } from '../data/Constants';
import { IImportAPIConfig } from '../../types';

const getImportApiDetails = ( type:string )=>{ //method which returns the file import api details based on type
  switch ( type ) {
    case EDataMaintenanceType.Prices : return { paramName:ImportConfig.Price.filename, url:ImportConfig.Price.url, fileType:EFileFormat.Zip, chunkSize:EFileSizeBytes.KB2000, maxFileSize:EFileSizeMB.MB20 };
    case EDataMaintenanceType.SalesText : return { paramName:ImportConfig.SalesText.filename, url:ImportConfig.SalesText.url, fileType:EFileFormat.Zip,chunkSize:EFileSizeBytes.KB1500, maxFileSize:EFileSizeMB.MB10 };
    case EDataMaintenanceType.ProductHierarchy : return { paramName:ImportConfig.ProductHierarchy.filename, url:ImportConfig.ProductHierarchy.url, fileType:EFileFormat.Xlsx, chunkSize:EFileSizeBytes.KB50, maxFileSize:EFileSizeMB.MB5 };
    default: return null;
  }
}

const resetDropzone = ( props )=>{ //reset the chunkUpload state variables after complete the Upload or while any error occured.
  const updatePropsData = JSON.parse( JSON.stringify( props.dataMaintainance.chunkUpload ) );
  updatePropsData.fileName = '';
  updatePropsData.fileId = '';
  updatePropsData.isUploaded = false;
  updatePropsData.isUploadProgress = false
  
  props.setChunkFileUpload( updatePropsData );
}

const getFileInfo = ( props ,fileId:string,fileName:string )=>{ //set the chunkUpload property while click import file in UI
  const updatePropsData = JSON.parse( JSON.stringify( props.dataMaintainance.chunkUpload ) );
  updatePropsData.fileName = fileName;
  updatePropsData.fileId = fileId;
  updatePropsData.isUploadProgress = fileId;
  props.setChunkFileUpload( updatePropsData );
  return updatePropsData;
}

//returns the  import api headers
const getApiHeaders = ( props )=>{
  return {
    Authorization: `Bearer ${props.token}`,
    Context: appSettings.Context
  }
}

const getDropzoneOptions = ( props ,apiConfig:IImportAPIConfig )=>{ //returns the default dropzone options based on the different import type
  return{
    maxFilesize:apiConfig?.maxFileSize,
    clickable:true,
    addRemoveLinks:false,
    maxFiles:1,
    autoProcessQueue:false,
    createImageThumbnails:false,
    paramName:apiConfig?.paramName,
    acceptedFiles:apiConfig?.fileType,
    uploadMultiple: false,
    chunking: true,
    forceChunking: true,
    chunkSize:apiConfig?.chunkSize, //in bytes
    parallelChunkUploads: false,
    retryChunks: true,
    retryChunksLimit: 2,
    url:`${appSettings.DisEndpoint}${apiConfig?.url}`,
    headers:getApiHeaders( props ),
    autoDiscover:true
  }
}

export const initDropZone = ( props, showAlertMessage )=>{ 
  const apiConfig:IImportAPIConfig = getImportApiDetails( props.type );
  return  new Dropzone( '#dropzone-upload',//initialize the dropzone options and events
    {
      ...getDropzoneOptions( props,apiConfig ),
      
      chunksUploaded() { //this event will trigger aftere all chunks files are uploaded the server
        props.setNotBusy();
        props.removeUploadFile();
        resetDropzone( props );
        showAlertMessage( EImportStatus.Success )
      },
      init: function () {
        this.on( 'addedfile', ( file ) =>{ //this event trigger after user selected the file from the folder.
          props.setUploadFile( file,this ); //set upload file info to the state
        } );

        this.on( 'sending', ( file, xhr ) =>{ //this event is trigger while sending file to API
          const isLastChunk = file?.upload?.totalChunkCount === file?.upload?.chunks.length ;
          if( file?.upload?.chunks.length === 1 ) {
          
            props.setBusy(); //set the loader

            const fileId = file.upload.uuid;
            const uploadFileName = fileId + '_' + file.name.replace( /\s/g, '' );
    
            xhr.setRequestHeader( ApiHeaders.FileName, uploadFileName ); //set the File name and File id to the request header
            xhr.setRequestHeader( ApiHeaders.FileId, fileId );
            
            this.chunkUpload = getFileInfo( props,fileId,uploadFileName );
          } else{
            xhr.setRequestHeader( ApiHeaders.FileName, this.chunkUpload.fileName );
            xhr.setRequestHeader( ApiHeaders.FileId, this.chunkUpload.fileId );            
          }

          xhr.setRequestHeader( ApiHeaders.CurrentChunk, file?.upload?.chunks.length ); //set the currentChunk and totalChunk to the request header
          xhr.setRequestHeader( ApiHeaders.TotalChunk, file?.upload?.totalChunkCount );

          if( isLastChunk ) { //set isUploaded flag to true once all the chunk files are uploaded to the API to changing the loader message
            this.chunkUpload.isUploaded = true;
            this.chunkUpload.isUploadProgress = false;
          }
        
          this.chunkUpload.uploadValue = file?.upload?.progress;
          props.setChunkFileUpload( this.chunkUpload );

        } );

        this.on( 'error', ( file )=> { //this event will trigger while getting error from the API or invalid file format/invalid file size
                   
          props.setNotBusy();//reset the loader
          props.removeUploadFile();

          if( !file.accepted && !file.name.includes( apiConfig.fileType ) ) {
            showAlertMessage( EImportStatus.InvalidType, apiConfig.fileType, apiConfig.maxFileSize ); //show the error message if file format is invalid
          } else if( !file.accepted && file.size > apiConfig?.maxFileSize * 1024 * 1024 ) {
            showAlertMessage( EImportStatus.InvalidSize, apiConfig.fileType, apiConfig.maxFileSize ); //show the error message if file size is invalid
          } else if( file.status === EImportStatus.Error ) {
            showAlertMessage( EImportStatus.Error, apiConfig.fileType, apiConfig.maxFileSize ); //show the error message if API is getting failed.
          }
        } );
      },
    } );
};

export const ImportConfig = {
  SalesText: {filename: 'salesTextXmlZip', url : '/salestext/v1/create/file'},
  ProductHierarchy: {filename: 'file', url : '/product/v1/hierarchy/create/file'},
  Price: {filename: 'pricingFile', url : '/price/v1/create/file'}
} 

export const ApiHeaders = {
  FileName: 'fileName',
  FileId: 'fileId',
  CurrentChunk: 'currentChunk',
  TotalChunk: 'totalChunk'
}
