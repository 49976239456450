import { ComparePHLegend, EDataMaintenanceType, EModuleType, EActivity} from "../../../data/Constants";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { dataMaintainanceState, dataMaintainanceDispatcher, alertMessageDispatcher, Combine, alertMessageState } from "../../../store";
import { MaintenanceTable } from "../../Layouts/DataTable";
import { Box, Button} from "@mui/material";
import ColorLegend from "../../ColorLegend";
import { IProductHierarchyProps } from "../../../../types";
import React from "react";

export const $ProductHierarchyCompare = (props: IProductHierarchyProps) => {
  
    const {t} = useTranslation();
    
    const callPublishAPI = (activity: EActivity) =>{
      props.publishProductHierarchy(props.token,activity).then( (res:any) => {
        if( res.error ) { 
          var errorMessage=t( 'messages.fail.discard' );
          if(activity === EActivity.Publish){
            errorMessage=t( 'messages.fail.publish' )
          }   
          props.setAlertMessage( {show:true,message:res.error.message ? res.error.message : errorMessage ,type:'error'} );
        }else{
          props.resetCompareProductHierarchy();
          props.setAlertMessage( {show:true,message: (activity === EActivity.Publish) ? t( 'messages.success.publish' ):t( 'messages.success.discard' ),type:'success'} );
        }
      } )
      props.closeDialog();
    };

    const handleDisableButton = ()=>{
      return props.dataMaintainance.compareProductHierarchy.data[1]?.length<=0;
    }

    const handleCloseCompare=()=>{
      props.closeDialog();
    }

    return <>
      <br/>
      <MaintenanceTable
       token={ props.token }
       type={ EDataMaintenanceType.CompareProductHierarchy }
       getDataList = { props.compareProductHierarchy }
       moduleType = { EModuleType.Datamaintainance }
       /> 
      <Box className="formActionButtons"> 
        {props.dataMaintainance.compareProductHierarchy.data[1]?.length>0 && 
        <ColorLegend legendItems =  { ComparePHLegend } /> } 
        <Button variant="contained" size="medium" disabled={handleDisableButton()} className="text-capitalize" onClick={ ()=>callPublishAPI(EActivity.Publish)}>{t( 'button.publish' )}</Button>
        <Button variant="contained" size="medium" disabled={handleDisableButton()} className="text-capitalize" onClick={ ()=>callPublishAPI(EActivity.Discard)}>{t( 'button.discard' )}</Button>
        <Button size="medium" className="text-capitalize" onClick = {()=>handleCloseCompare()}>{t( 'button.cancel' )}</Button>
      </Box> 
    </>
  }

  export const ProductHierarchyCompare = connect( Combine(dataMaintainanceState,alertMessageState), Combine( dataMaintainanceDispatcher, alertMessageDispatcher) )( $ProductHierarchyCompare) ;