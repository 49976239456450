import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField, Checkbox, FormControlLabel, Collapse, Alert, AlertTitle, RadioGroup, Grid, Radio } from '@mui/material';
import { AppStore, alertMessageState, alertMessageDispatcher, Combine, errorState, errorDispatcher, featureFlagDispatcher, featureFlagState, applicationDispatcher } from '../../store';
import { EActionType, EAction } from '../../data/Constants';
import { IFeatureFlagManipulationProps } from '../../../types';
import { displayMessage } from '../DisplayMessage';
import { useAuth } from 'oidc-react';

function callFeatureFlagApi( manipulationType:string, props:IFeatureFlagManipulationProps, formData, t:( message:string ) => void ) {
  if( manipulationType === EActionType.Edit ) {
    props.updateFeatureFlag( props.token, { ...formData, page:formData.page.trim(), flagName:formData.flagName.trimEnd().toUpperCase(), description:formData.description.trim() } ).then( res=>{
      handleApiResponse( res, props, t( 'messages.fail.update' ), t( 'messages.success.update' ) );
    } )
  }else{
    props.createFeatureFlag( props.token, { ...formData, page:formData.page.trim(), flagName:formData.flagName.trimEnd().toUpperCase(), description:formData.description.trim() } ).then( res=>{
      handleApiResponse( res, props, t( 'messages.fail.create' ), t( 'messages.success.create' ) );
    } )
  }
}

function handleApiResponse( res, props:IFeatureFlagManipulationProps, failMessage:string, successMessage:string ) {
  const featureFlags = AppStore.getState().featureFlags.featureFlags;
  if( res.error ) {
    const message = res.error.message ? res.error.message : failMessage;
    props.setAlertMessage( { show:true, message:message, type:'error' } )
  }else{
    props.getFeatureFlags( props.token, featureFlags.currentPage, featureFlags.recordsPerPage, featureFlags.searchKey )
    props.setAlertMessage( {show:true, message:successMessage, type:'success'} )
    props.closeDialog( false )
  }
}

const setApplicationDetails = async ( props, setApplicationsAvailable ) => {
  let applications = await props.getApplicationDetails( props.token, [] );
  applications = applications.data;
  setApplicationsAvailable( applications );
}

const loadFormData = async ( props, setInitialFeatureFlagData, setFormData ) => {
  let featureFlagDetails = await props.getFeatureFlagDetails( props.token, [props.id] );
  if( !featureFlagDetails.data ) {
    return null;
  }
  featureFlagDetails = featureFlagDetails.data[0];
  
  setInitialFeatureFlagData( featureFlagDetails );
  setFormData( featureFlagDetails );
}

const handleError = ( props, setDisplayAlertMessage ) => {
  if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }  
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;
  const value = name === 'isEditable' || name === 'isVisible' || name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

const formValidation = ( formValidationProps ) => {
  const { initialFeatureFlagData, formData, setDisplayNameErrorMessage, setDisplayDescriptionErrorMessage, setDisplayPageErrorMessage, setDisableButton } = formValidationProps;

  const name = formData.flagName.trimEnd();
  const page = formData.page.trimEnd();
  const description = formData.description.trimEnd();
  const validName = name.length > 2 && name.length <= 30 && /^[A-Za-z0-9]+((-|_|:)[A-Za-z0-9]+)*$/.test( name );
  const validPage = page.length > 2 && page.length <= 50 && /^[A-Za-z0-9]+((-|_|:)[A-Za-z0-9]+)*$/.test( page );
  const validDescription = description.length > 0 && description.length <= 100 ;
  const validApplication = !!formData.applicationName;
  const detailsUpdated = JSON.stringify( initialFeatureFlagData ) !== JSON.stringify( {...formData,flagName:name,page,description } );
    
  setDisplayNameErrorMessage( !validName );
  setDisplayDescriptionErrorMessage( !validDescription );
  setDisplayPageErrorMessage ( !validPage );

  setDisableButton( !validName || !validDescription || !validPage || !validApplication || !detailsUpdated );
}

const renderApplications = ( applicationsAvailable, initialFeatureFlagData, formData, setFormData, t ) => {
  return applicationsAvailable.length < 1 ? <p>{ t( 'messages.noApplicationsFound' )}</p> :
    <RadioGroup
      aria-labelledby="applicationName"
      name="applicationName"
      value={ formData.applicationName } 
      onChange={ ( e ) => {
        handleChange( e, setFormData )
      } }
    > <Grid container spacing={ 2 } className="pt-1">
        {applicationsAvailable.map( ( value ) => {
          if( value.isActive || initialFeatureFlagData.applicationName === value.name ) {
            return <Grid item xs={ 4 } key={ value.name } className="pt-0"> <FormControlLabel value={ value.name } label={ value.name } disabled={ !formData.isActive || !value.isActive } control={ < Radio /> }/></Grid>
          }
        } )
        }
      </Grid>
    </RadioGroup>
}

export const $FeatureFlagManipulation = ( props: IFeatureFlagManipulationProps ) => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [ formData, setFormData ] = useState( { 'flagName':'', 'description':'','applicationName':'', 'page':'', 'isEditable':true, 'isVisible':true, 'isActive':true } );
  const [ initialFeatureFlagData, setInitialFeatureFlagData ] = useState( { 'flagName':'', 'description':'','applicationName':'', 'page':'', 'isEditable':true, 'isVisible':true, 'isActive':true } );
  const [ applicationsAvailable, setApplicationsAvailable ] = useState( [] );
  const [ disableButton, setDisableButton ] = useState( true );
  const [ displayNameErrorMessage, setDisplayNameErrorMessage ] = useState( false );
  const [ displayDescriptionErrorMessage, setDisplayDescriptionErrorMessage ] = useState( false );
  const [ displayPageErrorMessage, setDisplayPageErrorMessage ] = useState( false );
  const [ displayAlertMessage, setDisplayAlertMessage ] = useState( false );

  const manipulationType = props.type; //To get the type of manipulation being performed
 
  useEffect( ()=>{
    setApplicationDetails( props, setApplicationsAvailable );
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialFeatureFlagData, setFormData )
    }
  },[] )

  useEffect( () => {
    handleError( props, setDisplayAlertMessage );
  }, [props.error] ) 

  useEffect( ()=>{
    formValidation( { initialFeatureFlagData, formData, setDisplayNameErrorMessage, setDisplayDescriptionErrorMessage, setDisplayPageErrorMessage, setDisableButton } );
  } )

  const handleSubmit = ( event ) => {
    event.preventDefault();
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false );
    callFeatureFlagApi( manipulationType, props, formData, t )
  }

  if( !props.featureFlags ) {
    return null;
  }

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    props.resetAlertMessage( );
    props.resetError();
    setDisplayAlertMessage( false );
  };

  return <Box className="manipulationBox pt-1">     
    <Box className="manipulationForm">
      <Collapse in={ displayAlertMessage } className="show-alert">
        <Alert className="errorMessage" severity="error" onClose={ handleClose }>
          <AlertTitle>{displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
        </Alert>
      </Collapse>

      <TextField name="flagName" label={ t( 'labels.flagName' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit } fullWidth value={ formData.flagName || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      /><br/>
      <Collapse in={ displayNameErrorMessage && formData.flagName.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>

      <TextField name="description" label={ t( 'labels.description' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit && !formData.isActive } fullWidth value={ formData.description || '' } onChange={ ( e )=>{
        handleChange( e,setFormData )
      } }
      /><br/>
      <Collapse in={ displayDescriptionErrorMessage && formData.description.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.100LimitErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>

      <fieldset className="inputFieldset w-100">
        <legend className="inputLegend" id="map">{t( 'labels.applicationName' ) + ' ' }<span className="PhilipsRed">*</span></legend>
        {renderApplications( applicationsAvailable, initialFeatureFlagData, formData, setFormData, t )}
      </fieldset>
      <br/>

      <TextField name="page" label={ t( 'labels.page' ) } required variant="outlined" size="small" fullWidth disabled={ !formData.isActive } value={ formData.page || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      /><br/>
      <Collapse in={ displayPageErrorMessage && formData.page.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>

      <span className = "d-flex justify-spacebetween">

        <FormControlLabel
          value={ formData.isVisible }
          checked={ !!formData.isVisible }
          disabled={ !formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isVisible' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isVisible"
        />
        <br/>

        <FormControlLabel
          value={ formData.isEditable }
          checked={ !!formData.isEditable }
          disabled={ !formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isEditable' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isEditable"
        />
        <br/>

        {!initialFeatureFlagData.isActive && manipulationType === EActionType.Edit ? <FormControlLabel
          value={ formData.isActive }
          checked={ !!formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isActive' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isActive"
        /> : null}
        <br/>
      </span>

      <Box className="formActionButtons">
        <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
          { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
        </Button>
        <Button size="medium" onClick={ ()=> {
          props.closeDialog( false );
          props.resetAlertMessage( );
          props.resetError(); 
          setDisplayAlertMessage( false ); 
        } } className="text-capitalize"
        >{ t( 'button.cancel' ) } </Button>
      </Box>

    </Box>
  </Box>

}
  
export const FeatureFlagManipulation = connect( Combine( featureFlagState, alertMessageState, errorState ),Combine( featureFlagDispatcher, applicationDispatcher, alertMessageDispatcher, errorDispatcher ) ) ( $FeatureFlagManipulation ) ;