import { roleActions,administrationActions } from '../store';
import { IRole } from '../../types/IComponentTypes';
import { handleError } from '../services/DataHelperFunctions';
import { EAction,EApiType,EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';

function handleSuccess( response ) {
  return {
    data: response.data
  } 
}

export const RoleApi = {
  getRoles: ( action: { token: string, page:number, limit:number, searchCode?: string,apiType:string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.RoleSearch}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/role/v1/read?${queryParams}`,
        [],
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: action.apiType && action.apiType === 'ALL' ? roleActions.SETROLES : administrationActions.SETROLES,
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            type: administrationActions.SETROLES,
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err,EAction.Read,EApiType.RoleKey );
      } )
  },
  
  createRole: ( action: { token: string, request: IRole } ) => {
    return AxiosClient
      .post( '/role/v1/create',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Create );
      } )
  },

  updateRole: ( action: { token: string, request: IRole } ) => {
    return AxiosClient
      .post( '/role/v1/update',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Update );
      } )
  },

  deleteRoles: ( action: { token: string, request: any } ) => {
    return AxiosClient
      .post( '/role/v1/delete',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },

  getRoleDetails: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/role/v1/read',
        action.request,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}
