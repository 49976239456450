import { Dispatch } from 'react';
import { IRoleAction,IState,IRole } from '../../../types';

export const roleActions = {
  ROLELIST: 'roleActions-ROLELIST',
  SETROLES: 'roleActions-SETROLES',
  CREATEROLE:'roleActions-CREATEROLE',
  DELETEROLES:'roleActions-DELETEROLES',
  UPDATEROLE:'roleActions-UPDATEROLE',
  GETROLEDETAILS:'roleActions-GETROLEDETAILS',
}
const initialState = {
  data:[]
}
export const roleDispatcher = ( dispatch: Dispatch<IRoleAction> ) => ( {
  getRoles:( token:string, page:number, limit:number, searchCode?:string,apiType?:string )=>dispatch( { type: 'roleActions-ROLELIST',token, page, limit, searchCode,apiType } ),
  createRole:( token: string,request:IRole )=>dispatch( { type: 'roleActions-CREATEROLE',token,request } ),
  updateRole:( token: string,request:IRole )=>dispatch( { type: 'roleActions-UPDATEROLE',token,request } ),
  deleteRoles:( token: string,request:IRole )=>dispatch( { type: 'roleActions-DELETEROLES',token,request } ),
  getRoleDetails:( token: string,request:string[] )=>dispatch( { type: 'roleActions-GETROLEDETAILS',token,request } ),
} )

export const roleReducer = ( state = initialState, action: IRoleAction = { type: '' } ) => {
  if ( action.type === roleActions.SETROLES ) {
    return { ...state, data: action.data };
  } else{
    return state;
  }
}
export const roleState = ( state: IState ) => ( { roles: state.roles } );