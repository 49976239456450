import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField,Checkbox,FormControlLabel, Collapse, Alert, AlertTitle } from '@mui/material';
import { AppStore, roleDispatcher,roleState,alertMessageState,alertMessageDispatcher,Combine,errorState,errorDispatcher } from '../../../store';
import { EActionType,EAction } from '../../../data/Constants';
import { IRoleManipulationProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage';
import { useAuth } from 'oidc-react';

function callRoleApi( manipulationType:string,props:IRoleManipulationProps,formData,t:( message:string ) => void ) {
  if( manipulationType === EActionType.Edit ) {
    props.updateRole( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim()} ).then( res=>{
      handleApiResponse( res,props,t( 'messages.fail.update' ),t( 'messages.success.update' ) );
    } )
  }else{
    props.createRole( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim()} ).then( res=>{
      handleApiResponse( res,props,t( 'messages.fail.create' ),t( 'messages.success.create' ) );
    } )
  }
}

function handleApiResponse( res, props:IRoleManipulationProps, failMessage:string, successMessage:string ) {
  const roles = AppStore.getState().administartion.roles;
  if( res.error ) {
    const message = res.error.message ? res.error.message : failMessage
    props.setAlertMessage( {show:true,message:message,type:'error'} )
  }else{
    props.getRoles( props.token,roles.currentPage,roles.recordsPerPage,roles.searchKey )
    props.setAlertMessage( {show:true,message:successMessage,type:'success'} )
    props.closeDialog( false )
  }
}

const loadFormData = async ( props, setInitialRoleData, setFormData ) => {
  let roleDetails = await props.getRoleDetails( props.token,[props.id] );
  if( !roleDetails.data ) {
    return null;
  }
  roleDetails = roleDetails.data[0];
  setInitialRoleData( roleDetails );
  setFormData( roleDetails );
}

const handleError = ( props, setDisplayAlertMessage ) => {
  if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }  
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;    
  const value = name === 'isDefault' || name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

export const $RoleManipulation = ( props:IRoleManipulationProps ) => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [formData, setFormData] = useState( {'name':'', 'description':'', 'isDefault':false, 'isActive':true} );
  const [initialRoleData, setInitialRoleData] = useState( {'name':'', 'description':'', 'isDefault':false, 'isActive':true} );
  const [disableButton, setDisableButton] = useState( true );
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState( false );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );

  const manipulationType = props.type; //To get the type of manipulation being performed
 
  useEffect( ()=>{   
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialRoleData, setFormData )
    }
  },[] )

  useEffect( () => {
    handleError( props, setDisplayAlertMessage );
  }, [props.error] ) 

  useEffect( ()=>{
    const name = formData.name.trimEnd();
    const validName = name.length > 2 && /^[A-Za-z0-9]+(_[A-Za-z0-9]+)*$/.test( name );
    const detailsUpdated = JSON.stringify( initialRoleData ) !== JSON.stringify( {...formData,name } )
    setDisplayNameErrorMessage( !validName )
    setDisableButton( !validName || !detailsUpdated )
  } )

  const handleSubmit = ( event ) => {
    event.preventDefault();
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false );
    callRoleApi( manipulationType,props,formData,t )
  }

  if( !props.roles ) {
    return null;
  }

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    props.resetAlertMessage( );
    props.resetError();
    setDisplayAlertMessage( false );
  };

  return <>
    <Box className="manipulationBox pt-1">     
      <Box className="manipulationForm">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ handleClose }>
            <AlertTitle>{displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
          </Alert>
        </Collapse>
        <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit } fullWidth value={ formData.name || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } }
        /><br/>
        <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
          <Alert className="errorMessage" severity="error">
            <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
          </Alert>
        </Collapse>
        <br/>
        <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit && !formData.isActive } fullWidth value={ formData.description || '' } onChange={ ( e )=>{
          handleChange( e,setFormData )
        } }
        /><br/>
        <br/>
     
        <FormControlLabel
          value={ formData.isDefault }
          checked={ !!formData.isDefault }
          control={ <Checkbox /> }
          label={ t( 'labels.isDefault' ) }
          labelPlacement="end"
          disabled={ manipulationType === EActionType.Edit && !formData.isActive }
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isDefault"
        />

        {!initialRoleData.isActive && manipulationType === EActionType.Edit ? <FormControlLabel
          value={ formData.isActive }
          checked={ !!formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isActive' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isActive"
        /> : null}        
        <br/>

        <Box className="formActionButtons">
          <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
            { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
          </Button>
          <Button size="medium" onClick={ ()=> {
            props.closeDialog( false );
            props.resetAlertMessage( );
            props.resetError(); 
            setDisplayAlertMessage( false ); 
          } } className="text-capitalize"
          >{ t( 'button.cancel' ) } </Button>
        </Box>
      </Box>
    </Box>
  </>
}
  
export const RoleManipulation = connect( Combine( roleState,alertMessageState,errorState ),Combine( roleDispatcher,alertMessageDispatcher,errorDispatcher ) ) ( $RoleManipulation ) ;