import { dataMaintenanceActions } from '../store';
import { handleError } from '../services/DataHelperFunctions';
import { EAction, EActivity, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';

function handleSuccess( response ) {
  return {
    data: response.data
  } 
}

export const ProductHierarchyApi = {
  importProductHierarchy: ( action: { token: string,request } ) => {
    return AxiosClient
      .post( '/product/v1/hierarchy/create/file',
        {file: action.request},
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
     
  getProductHierarchy: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    const requestBody = action.searchCode ? action.searchCode : '';
    return AxiosClient
      .post( `/product/v1/hierarchy/read?${EUrlParams.Page}=${action.page}&${EUrlParams.Limit}=${action.limit}`,
        { code: requestBody },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: dataMaintenanceActions.SETPRODUCTHIERARCHY,
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            type: dataMaintenanceActions.SETPRODUCTHIERARCHY,
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.ProductHierarchyKey );
      } )
  },

  compareProductHierarchy: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    const requestBody = action.searchCode ? action.searchCode : '';
    return AxiosClient
      .post( `/product/v1/hierarchy/compare?${EUrlParams.Page}=${action.page}&${EUrlParams.Limit}=${action.limit}`,
        { code: requestBody },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: dataMaintenanceActions.SETCOMPAREPRODUCTHIERARCHY,
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit,
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            type: dataMaintenanceActions.SETCOMPAREPRODUCTHIERARCHY,
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1,
          }
        }
        return handleError( err, EAction.Read, EApiType.ProductHierarchyKey );
      } )
  },
    
  publishProductHierarchy: ( action: { token: string, activity:EActivity } ) => {
    return AxiosClient
      .post( '/product/v1/hierarchy/publish', 
        {activity: action.activity},
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }    
}