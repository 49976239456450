export const rowFlex = ( justifyContent = 'flex-start', alignItems = 'flex-start' ) => 
  ( { display: 'flex', flexDirection: 'row', justifyContent, alignItems } )

export const colFlex = ( justifyContent = 'flex-start', alignItems = 'flex-start' ) => 
  ( { display: 'flex', flexDirection: 'column', justifyContent, alignItems } )

export const fixHeight = ( height : string ) => ( { minHeight: height, height, maxHeight: height } )
export const fixWidth = ( width : string ) => ( { minWidth: width, width, maxWidth: width } )
export const squareSize = ( size : string ) => ( { height: size, width: size } )

export const PhilipsBlue = '#1474A4';