import { dataMaintenanceActions } from '../store';
import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';


export const DataMaintenanceApi = {
  
  getImages: ( action: { token: string, page:number, limit:number, materialCode?: string } ) => {
    const requestBody = action.materialCode ? [action.materialCode] : []
    return AxiosClient
      .post( `/product/v1/images/read?${EUrlParams.Page}=${action.page}&${EUrlParams.Limit}=${action.limit}`,
        requestBody,
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          type: dataMaintenanceActions.SETIMAGES,
          data: response.data.productImages,
          searchKey: action.materialCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            type: dataMaintenanceActions.SETIMAGES,
            data: [],
            searchKey: action.materialCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.ImagesKey );
      } )
  },
  
  getCountryList: ( action: { token: string } ) => {
    return AxiosClient
      .post( '/market/v1/read',
        {},
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        return {
          type: dataMaintenanceActions.SETCOUNTRYLIST,
          data: response.data
        } 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  } 
}