import { EDataTableType } from '../data/Constants';

export function updateMaintenanceData( state, action, type ) {
  const newData = state[type];
  if( action.page === 1 ) {
    newData['data'] = {} // To reset data on every load of first page as change in recordLength might affect the functionality
  }
  if( Object.keys( state[type].data ).length > 5 ) { // Delete the second entry of data element as first element is the initial load data
    delete newData['data'][Object.keys( newData.data )[1]]
  }
  newData['searchKey'] = '';
  if ( type !== EDataTableType.ApiKeys ) {
    action.data = action.data.map( ( item, index ) => ( { ...item, id: action.page + '-' + index + 1 } ) )
  }
  newData['data'] = {...newData['data'],[action.page]:action.data ? action.data : []};
  newData['currentPage'] = action.page;
  newData['totalCount'] = action.totalRecords;
  newData['recordsPerPage'] = action.recordsPerPage ? action.recordsPerPage : 15;
  return { ...state, [type]:newData };    
}
  
export function updateMaintenanceSearchData ( state, action, type ) {
  const newData = state[type];
  if( action.page === 1 ) {
    newData['searchData'] = {} // To reset searchData on every load of first page as change in recordLength might affect the functionality
  }
  if( Object.keys( state[type].searchData ).length > 5 ) { // Delete the second entry of searchData element as first element is the initial load data
    delete newData['searchData'][Object.keys( newData.searchData )[1]]
  }
  newData['searchKey'] = action.searchKey;
  if ( type !== EDataTableType.ApiKeys ) {
    action.data = action.data.map( ( item, index ) => ( { ...item, id: action.page + '-' + index + 1 } ) )
  }
  newData['searchData'] = {...newData['searchData'],[action.page]:action.data ? action.data : []};
  newData['currentPage'] = action.page;
  newData['totalCount'] = action.totalRecords;
  newData['recordsPerPage'] = action.recordsPerPage ? action.recordsPerPage : 15;
  return { ...state, [type]:newData };   
}

//To derive deletion message
export const getDelMsgForSelectedKeys = ( keySelected ) => {
  const selectedKey = [...keySelected];
  return selectedKey.join( ', ' ) + '.';
}

export function handleError( err:any,action?:string,apiType?:string ) {
  return {
    type: 'error-SET',
    error: {
      type: apiType ? apiType : '',
      code: err.response.status,
      action: action ? action : '',
      message: err.response.data?.Message || Object.values( err.response.data.errors )[0][0],
    }
  };
}

export const formatDateString = ( dateValue: string )=> {
  const date = new Date( dateValue );
  const month = ( '0' + ( date.getMonth() + 1 ) ).slice( -2 );
  const day = ( '0' + date.getDate() ).slice( -2 );
  const year = date.getFullYear();
  const hour = ( '0' + date.getHours() ).slice( -2 );
  const min = ( '0' + date.getMinutes() ).slice( -2 );
  const seg = ( '0' + date.getSeconds() ).slice( -2 );
  return month + '-' + day + '-' + year + ' ' + hour + ':' + min + ':' + seg + ' UTC';
} 

/**
 * To get the maximum date from given dates in string Array
 * @param datesArray
 * @returns Maximum date in Formatted way
 */
export const getMaxDate = ( datesArray: string[] ) => {
  return datesArray.length > 0 ? formatDateString( datesArray.reduce( ( a, b ) => a > b ? a : b ) ) : ''; 
}