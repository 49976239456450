import { Box } from '@mui/material';
import React from 'react';
import { LegendItem } from '../../types';

const ColorLegend = (props:{legendItems: LegendItem[]})=>{

    return (
        <Box style={{display:'flex',  marginRight:'37%',}}>
            {props.legendItems.map((item,index)=>(
                <Box 
                key={index}
                style={{
                    display:'flex',
                    alignItems:'center',
                    marginRight:'10px',
                }}
                >
                <Box 
                className = {item.shape ==='circle'? 'circleLegend':'squareLegend'}
                style={{backgroundColor:item.color}}> 
                </Box>
                <span>{item.label}</span>
                </Box>
            ))}
        </Box>
    )
}

export default ColorLegend;