import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { connect } from 'react-redux';
import { dataMaintainanceState, dataMaintainanceDispatcher } from '../../store';
import { displayMessage } from '../DisplayMessage';

export const $SalesText = ( props: { token: any; getSalesText: any; } ) => {
  const {t} = useTranslation();

  if( props.error.code && props.error.type === EApiType.SalesTextKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.salesText' )} </h3>
      <MaintenanceTable
        token={ props.token }
        type={ EDataMaintenanceType.SalesText }
        getDataList = { props.getSalesText }
        moduleType = { EModuleType.Datamaintainance }
      />
  </>
}

export const SalesText = connect( dataMaintainanceState, dataMaintainanceDispatcher )( $SalesText ) ;