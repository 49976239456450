import axios, { AxiosError, AxiosResponse } from 'axios';
import { appSettings } from '../settings';
import { v4 as uuidv4 } from 'uuid';
import { SessionStore } from '../services/SessionStore';
import { authSettings } from './OidcSettings';

export const getAuthToken = ()=>{
  const sessionKey = `oidc.user:${authSettings.AuthorityUrl}:${authSettings.ClientId}`
  return SessionStore.get( sessionKey )?.access_token;
}


const AxiosClient = axios.create( {
  baseURL: appSettings.DisEndpoint,
  timeout: 60000,
  withCredentials: false,
} );
  
  
AxiosClient.interceptors.request.use( ( config ) => {
  const token = getAuthToken();
  config.headers['X-CORRELATION-ID'] = uuidv4();
  config.headers['Context'] = appSettings.Context;
  config.headers['Content-Type'] = 'application/json; charset=utf-8';
  config.headers['Accept'] = 'application/json';
  config.headers['X-Requested-With'] = 'CfLM-PHC-Admin';
  if( appSettings.STAuth ) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
} );


AxiosClient.interceptors.response.use(
  ( response: AxiosResponse ) => {
    return response;
  },
  ( error: AxiosError ) => {
    throw error;
  }
);


export default AxiosClient;