import { Dispatch } from 'react';
import { IAction, IDispatcher, IState, IStateMapper } from '../../types';

/**
 * @description Combines the states and dispatchers
 * @param {...function} args A list of arguments which should be combined
 * @returns {object} A combined object with the states or dispatchers
 */
export const Combine = ( ...args: IStateMapper[] | IDispatcher[] ) => ( reduxParam: IState | Dispatch<IAction> ) => {
  let result = {};

  for ( const arg of args ) {
    if( isStateMapper( arg ) ) {
      result = { ...result, ...arg( reduxParam as IState ) };
    } else {
      result = { ...result, ...arg( reduxParam as Dispatch<IAction> ) };
    }
  }

  return result;
};

function isStateMapper( arg: IStateMapper | IDispatcher ): arg is IStateMapper {
  return <IStateMapper>arg !== undefined;
}