import { Dispatch } from 'react';
import { SessionStore } from '../../services/SessionStore';
import { IUserSettingsAction, IState, IUserSettings } from '../../../types';

export const userSettingsActions = {
  SET: 'userSettings-SET',
  RESET: 'userSettings-RESET'
}

const sessionStoreKey = 'user-settings';
const defaultUserSettings = {
  language: 'English',
  showCodes: true,
  currency:{
    'cc': 'EUR',
    'symbol': '\u20ac',
    'name': 'European Euro'
  },
  showSectionTabs: true,
  showSummary: true,
  activeTab: 0,
  forceShowSectionTabs: false,
  forceShowSummary: false,
  pageSize: {
    isExtraSmall: false,
    isSmall: false, 
    isMedium: false,
    isLarge: false,
    isExtraLarge: false
  }
}

const getDefaultSettings = () => {
  const storedSettings = SessionStore.get( sessionStoreKey );
  let userSettings;

  if( !storedSettings ) {
    userSettings = defaultUserSettings;
  } else {
    for( const key in defaultUserSettings ) {
      if( !( key in storedSettings ) ) {
        //set default settings if stored settings are missing data
        userSettings = defaultUserSettings;
        break;
      }
    }

    userSettings = userSettings || storedSettings;
  }  

  return { ...userSettings, forceShowSectionTabs: false, forceShowSummary: false };
}

export const userSettingsDispatcher = ( dispatch: Dispatch<IUserSettingsAction> ) => ( {
  setUserSettings: ( userSettings: IUserSettings ) => dispatch( { type: userSettingsActions.SET, userSettings } ),
  resetUserSettings: () => dispatch( { type: userSettingsActions.RESET } ),
} )

export const userSettingsReducer = ( state = getDefaultSettings(), action: IUserSettingsAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case userSettingsActions.SET:
      newState = { ...state, ...action.userSettings };
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    case userSettingsActions.RESET:
      newState = { ...state, ...defaultUserSettings };
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    default:
      return state;
  }
};

export const userSettingsState = ( state: IState ) => ( { userSettings: state.userSettings } );