import React, {useEffect}from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../../data/Constants';
import { connect } from 'react-redux';
import { dataMaintainanceState, dataMaintainanceDispatcher } from '../../../store';
import { displayMessage } from '../../DisplayMessage';

export const $PriceListMap = ( props: { token: any; getPriceListMap: any; getCountryList:any;} ) => {
  const {t} = useTranslation();

  useEffect( ()=>{
    props.getCountryList( props.token )
  },[] )

  if( props.error.code && props.error.type === EApiType.PriceListMapKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.priceListMap' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.PriceListMap }
      getDataList = { props.getPriceListMap }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}

export const PriceListMap = connect( dataMaintainanceState, dataMaintainanceDispatcher )( $PriceListMap ) ;