import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dataMaintainanceDispatcher, dataMaintainanceState, alertMessageState, alertMessageDispatcher, Combine, AppStore } from '../../../store';
import { EApiType, EDataTableType, EModuleType } from '../../../data/Constants';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { displayMessage } from '../../DisplayMessage';
import { getDelMsgForSelectedKeys } from '../../../services/DataHelperFunctions';

export const $MarketManagement = ( props ) => {
  const {t} = useTranslation();

  const deleteItems = ( req )=>{
    const markets = AppStore.getState().dataMaintainance.markets;
    props.deleteMarket( props.token,req ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.markets', { length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( {show:true, message:message, type:'error'} )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.markets', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getMarkets( props.token, markets.currentPage, markets.recordsPerPage, markets.searchKey );
      }
    } )
    
  }

  if( props.error.code && props.error.type === EApiType.MarketKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <><h3 className="mainHeader"> {t( 'labels.marketManagement' )}</h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Markets }
      getDataList = { props.getMarkets }
      moduleType = { EModuleType.Datamaintainance }
      deleteItems={ deleteItems }
    /></>
}

export const MarketManagement = connect( Combine( dataMaintainanceState,alertMessageState ),Combine( dataMaintainanceDispatcher,alertMessageDispatcher ) ) ( $MarketManagement ) ;