import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { apikeyDispatcher, apikeyState, alertMessageDispatcher, alertMessageState, Combine, AppStore } from '../../../store';
import { IApiKeyManagementProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { getDelMsgForSelectedKeys } from '../../../services/DataHelperFunctions';

export const $ApiKeyManagement = ( props:IApiKeyManagementProps ) => {

  const {t} = useTranslation();

  useEffect( () => { 
    props.getChannels( props.token );
    props.getServiceUsers( props.token );
    props.getPropertyList( props.token );
    props.getPrivileges( props.token );
  },[] )

  const deleteItems = ( req,keySelected )=>{
    const apikeys = AppStore.getState().administartion.apikeys;
    props.deleteApiKeys( props.token,{'ids':req} ).then( res=>{
      if( res.error ) {
        const message = t( 'messages.fail.delete.apikeys', { length:req.length, list:getDelMsgForSelectedKeys( req ) } );
        props.setAlertMessage( {show:true, message:message, type:'error'} )
      }else{
        props.setAlertMessage( {show:true, message:t( 'messages.success.delete.apikeys', { length:req.length, list:getDelMsgForSelectedKeys( req ) } ), type:'success'} )
        props.getApiKeys( props.token, apikeys.currentPage, apikeys.recordsPerPage, apikeys.searchKey );
      }
    } )
  }
    
  if( !props.apikeys ) {
    return null;
  }
  
  if( props.error.code && props.error.type === EApiType.ApiKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }
 
  return <>
    <h3 className="mainHeader"> {t( 'labels.apiKeyManagement' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.ApiKeys }
      getDataList = { props.getApiKeys }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}
  
export const ApiKeyManagement = connect( 
  Combine( apikeyState, alertMessageState ),
  Combine( apikeyDispatcher, alertMessageDispatcher ) ) ( $ApiKeyManagement ) ;