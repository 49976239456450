import { Dispatch } from 'react';
import { IApplicationAction, IState, IApplication } from '../../../types';

export const applicationActions = {
  APPLICATIONLIST: 'applicationActions-APPLICATIONLIST',
  CREATEAPPLICATION:'applicationActions-CREATEAPPLICATION',
  UPDATEAPPLICATION:'applicationActions-UPDATEAPPLICATION',
  DELETEAPPLICATIONS:'applicationActions-DELETEAPPLICATIONS',
  GETAPPLICATIONDETAILS:'applicationActions-GETAPPLICATIONDETAILS'
}

const initialState = {
  data:[]
}

export const applicationDispatcher = ( dispatch: Dispatch<IApplicationAction> ) => ( {
  getApplications:( token:string, page:number, limit:number, searchCode?:string )=>dispatch( { type: 'applicationActions-APPLICATIONLIST',token, page, limit, searchCode } ),
  createApplication:( token: string,request:IApplication )=>dispatch( { type: 'applicationActions-CREATEAPPLICATION',token,request } ),
  updateApplication:( token: string,request:IApplication )=>dispatch( { type: 'applicationActions-UPDATEAPPLICATION',token,request } ),
  deleteApplications:( token: string,request:IApplication )=>dispatch( { type: 'applicationActions-DELETEAPPLICATIONS',token,request } ),
  getApplicationDetails:( token: string,request:string[] )=>dispatch( { type: 'applicationActions-GETAPPLICATIONDETAILS',token,request } ),
} )

export const applicationReducer = ( state = initialState, action: IApplicationAction = { type: '' } ) => {
  return state;
}

export const applicationState = ( state: IState ) => ( { applications: state.applications } );