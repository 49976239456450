import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { ILoadingScreenProps } from '../../types';
import { busyState,Combine,dataMaintainanceState,dataMaintainanceDispatcher } from '../store';
import { useTranslation } from 'react-i18next';
import { ProgresBarwithLabelStyle } from '../../style/Styles';

function CircularProgressWithLabel( props ) {//show circular loading with file upload percentage
  const chunkUpload = props.props.dataMaintainance.chunkUpload;
  return (
    <Box sx={ ProgresBarwithLabelStyle.root }>
      <CircularProgress size={ 50 } variant="determinate" { ...props } />
      <Box
        sx={ ProgresBarwithLabelStyle.content }
      >
        {chunkUpload.isUploadProgress && `${Math.round( chunkUpload.uploadValue )}%`}
      </Box>
    </Box>
  );
}
/**
 * An overlay with a loading circle which is displayed if there is some background task running
 * @param {ILoadingScreenProps} props the properties for the loading screen component
 * @returns {JSX.Element} the overlay with the loading circle component
 */
export const $LoadingScreen = ( props: ILoadingScreenProps ) => {
  const {t} = useTranslation();
  const propFileState = props.dataMaintainance.uploadFile.file;
  const chunkUpload = props.dataMaintainance.chunkUpload;

  return <> 
    {<Backdrop open={ props.busy } >
      <Box className={ propFileState?.name  && (chunkUpload.isUploadProgress || chunkUpload.isUploaded ) ? 'loading-message' : '' } sx={ {display:'block',textAlign:'center'} }>
        {( Object.keys( propFileState ).length === 0 || chunkUpload.isUploaded || (Object.keys( propFileState ).length> 0 && !chunkUpload.isUploadProgress ) ) &&
         <CircularProgress/>
        }
        {propFileState?.name && chunkUpload.isUploadProgress && <CircularProgressWithLabel props={ props } value={ chunkUpload.uploadValue }/>}
        {propFileState?.name && chunkUpload.isUploaded &&
   <Box>
     { t( 'messages.success.import' ) }
   </Box>}
      </Box>
    </Backdrop>}
  
  </>
}

export const LoadingScreen = connect( Combine( busyState ,dataMaintainanceState ), Combine( dataMaintainanceDispatcher ) )( $LoadingScreen );