import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField, Checkbox, FormControlLabel, Collapse, Alert, AlertTitle } from '@mui/material';
import { AppStore, alertMessageState, alertMessageDispatcher, Combine, errorState, errorDispatcher, applicationState, applicationDispatcher } from '../../../store';
import { EActionType, EAction } from '../../../data/Constants';
import { IApplicationManipulationProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage';
import { useAuth } from 'oidc-react';

function callApplicationApi( manipulationType:string, props:IApplicationManipulationProps, formData, t:( message:string ) => void ) {
  if( manipulationType === EActionType.Edit ) {
    props.updateApplication( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim()} ).then( res=>{
      handleApiResponse( res, props, t( 'messages.fail.update' ), t( 'messages.success.update' ) );
    } )
  }else{
    props.createApplication( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim()} ).then( res=>{
      handleApiResponse( res, props, t( 'messages.fail.create' ), t( 'messages.success.create' ) );
    } )
  }
}

function handleApiResponse( res, props:IApplicationManipulationProps, failMessage:string, successMessage:string ) {
  const applications = AppStore.getState().administartion.applications;
  if( res.error ) {
    const message = res.error.message ? res.error.message : failMessage;
    props.setAlertMessage( { show:true, message:message, type:'error' } )
  }else{
    props.getApplications( props.token, applications.currentPage, applications.recordsPerPage, applications.searchKey )
    props.setAlertMessage( {show:true, message:successMessage, type:'success'} )
    props.closeDialog( false )
  }
}

const loadFormData = async ( props, setInitialApplicationData, setFormData ) => {
  let applicationDetails = await props.getApplicationDetails( props.token, [props.id] );
  if( !applicationDetails.data ) {
    return null;
  }
  applicationDetails = applicationDetails.data[0];
  setInitialApplicationData( applicationDetails );
  setFormData( applicationDetails );
}

const handleError = ( props, setDisplayAlertMessage ) => {
  if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }  
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;    
  const value = name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

export const $ApplicationManipulation = ( props: IApplicationManipulationProps ) => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [ formData, setFormData ] = useState( {'name':'', 'description':'', 'isActive':true} );
  const [ initialApplicationData, setInitialApplicationData ] = useState( {'name':'', 'description':'', 'isActive':true} );
  const [ disableButton, setDisableButton ] = useState( true );
  const [ displayNameErrorMessage, setDisplayNameErrorMessage ] = useState( false );
  const [ displayDescriptionErrorMessage ,setDisplayDescriptionErrorMessage ] = useState( false );
  const [ displayAlertMessage, setDisplayAlertMessage ] = useState( false );

  const manipulationType = props.type; //To get the type of manipulation being performed
 
  useEffect( ()=>{   
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialApplicationData, setFormData )
    }
  },[] )

  useEffect( () => {
    handleError( props, setDisplayAlertMessage );
  }, [props.error] ) 

  useEffect( ()=>{
    const name = formData.name.trimEnd();
    const description = formData.description.trimEnd();
    const validName = name.length > 2 && name.length <= 10 && /^[A-Za-z0-9]+((-|_)[A-Za-z0-9]+)*$/.test( name );
    const validDescription = description.length <= 100;
    const detailsUpdated = JSON.stringify( initialApplicationData ) !== JSON.stringify( {...formData,name } )
    setDisplayNameErrorMessage( !validName )
    setDisplayDescriptionErrorMessage( !validDescription )
    setDisableButton( !validName || !validDescription || !detailsUpdated )
  } )

  const handleSubmit = ( event ) => {
    event.preventDefault();
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false );
    callApplicationApi( manipulationType, props, formData, t )
  }

  if( !props.applications ) {
    return null;
  }

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    props.resetAlertMessage( );
    props.resetError();
    setDisplayAlertMessage( false );
  };

  return <Box className="manipulationBox pt-1">     
    <Box className="manipulationForm">
      <Collapse in={ displayAlertMessage } className="show-alert">
        <Alert className="errorMessage" severity="error" onClose={ handleClose }>
          <AlertTitle>{displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
        </Alert>
      </Collapse>

      <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit } fullWidth value={ formData.name || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      /><br/>
      <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>

      <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit && !formData.isActive } fullWidth value={ formData.description || '' } onChange={ ( e )=>{
        handleChange( e,setFormData )
      } }
      /><br/>
      <Collapse in={ displayDescriptionErrorMessage && formData.description.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.100LimitErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>
      {!initialApplicationData.isActive && manipulationType === EActionType.Edit ? <FormControlLabel
        value={ formData.isActive }
        checked={ !!formData.isActive }
        control={ <Checkbox /> }
        label={ t( 'labels.isActive' ) }
        labelPlacement="end"
        onChange={ ( e )=>{
          handleChange( e, setFormData )
        } }
        name="isActive"
      /> : null}        
      <br/>

      <Box className="formActionButtons">
        <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
          { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
        </Button>
        <Button size="medium" onClick={ ()=> {
          props.closeDialog( false );
          props.resetAlertMessage( );
          props.resetError(); 
          setDisplayAlertMessage( false ); 
        } } className="text-capitalize"
        >{ t( 'button.cancel' ) } </Button>
      </Box>

    </Box>
  </Box>

}
  
export const ApplicationManipulation = connect( Combine( applicationState, alertMessageState, errorState ),Combine( applicationDispatcher, alertMessageDispatcher, errorDispatcher ) ) ( $ApplicationManipulation ) ;