import { Dispatch } from 'react';
import { IUserAction, IState, IUser } from '../../../types';

export const userActions = {
  USERLIST: 'userActions-USERLIST',
  SETUSERS: 'userActions-SETUSERS',
  SETAUTHSTATE: 'userActions-SETAUTHSTATE',
  DELETEUSERS:'userActions-DELETEUSERS',
  CREATEUSER:'userActions-CREATEUSER',
  UPDATEUSER:'userActions-UPDATEUSER',
  UPDATEUSERROLEASSOCIATION:'userActions-UPDATEUSERROLEASSOCIATION',
  EXPORTUSERDETAILS:'userActions-EXPORTUSERDETAILS',
  GETUSERDETAILS:'userActions-GETUSERDETAILS',
  GETLOGGEDINUSERDETAILS:'userActions-GETLOGGEDINUSERDETAILS',
  SETLOGGEDINUSERDETAILS:'userActions-SETLOGGEDINUSERDETAILS',
}

const initialState = {
  data:[],
  authState: 'initial',
  details:{}
}

export const userDispatcher = ( dispatch: Dispatch<IUserAction> ) => ( {
  getUsers:( token:string, page:number, limit:number, searchCode?:string )=>dispatch( { type: 'userActions-USERLIST',token, page, limit, searchCode } ),
  createUser:( token: string,request:IUser )=>dispatch( { type: 'userActions-CREATEUSER',token,request } ),
  updateUser:( token: string,request:IUser )=>dispatch( { type: 'userActions-UPDATEUSER',token,request } ),
  setAuthState: ( authState: string ) => dispatch( { type: userActions.SETAUTHSTATE, authState } ),
  deleteUsers:( token: string,request:IUser )=>dispatch( { type: 'userActions-DELETEUSERS',token,request } ),
  updateUserRoleAssociation:( token: string,request:IUser )=>dispatch( { type: 'userActions-UPDATEUSERROLEASSOCIATION',token,request } ),
  exportUserDetails:( token:string )=>dispatch( {type: 'userAction-EXPORTUSERDETAILS',token} ),
  getUserDetails:( token: string,request:string[] )=>dispatch( { type: 'userActions-GETUSERDETAILS',token,request } ),
  getLoggedInUserDetails:( token: string )=>dispatch( { type: 'userActions-GETLOGGEDINUSERDETAILS',token } ),
} )

export const userReducer = ( state = initialState, action: IUserAction = { type: '' } ) => {
  switch ( action.type ) {
    case userActions.SETUSERS: 
      const newData = state[type];
      if( action.page === 1 ) {
        newData['data'] = {} // To reset data on every load of first page as change in recordLength might affect the functionality
      }
      if( Object.keys( state[type].data ).length > 5 ) { // Delete the second entry of data element as first element is the initial load data
        delete newData['data'][Object.keys( newData.data )[1]]
      }
      newData['searchKey'] = '';
      newData['data'] = {...newData['data'],[action.page]:action.data ? action.data : []};
      newData['currentPage'] = action.page;
      newData['totalCount'] = action.totalRecords;
      return { ...state, [type]:newData }; 
    case userActions.SETAUTHSTATE: {
      return { ...state, authState: action.authState };
    }
    case userActions.SETLOGGEDINUSERDETAILS:{
      return {...state, details: action?.data};
    }
    default:
      return state;
  }
}

export const userState = ( state: IState ) => ( { users: state.users } );