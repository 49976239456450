import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { connect } from 'react-redux';
import { dataMaintainanceState, dataMaintainanceDispatcher } from '../../store';
import { IPricesProps } from '../../../types';
import { displayMessage } from '../DisplayMessage';

export const $Prices = ( props:IPricesProps ) => {
  const {t} = useTranslation();
  
  if( props.error.code && props.error.type === EApiType.PriceKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.price' )} </h3>
      <MaintenanceTable
        token={ props.token }
        type={ EDataMaintenanceType.Prices }
        getDataList = { props.getPrice }
        moduleType = { EModuleType.Datamaintainance }
      />
  </>
}

export const Prices = connect( dataMaintainanceState, dataMaintainanceDispatcher )( $Prices ) ;