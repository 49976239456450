import i18n from 'i18next';
import { localeText } from './localization';
import { initReactI18next } from 'react-i18next';

const resources = localeText
i18n
  .use( initReactI18next ) 
  .init( {
    resources,
    lng: 'en_US',
    fallbackLng: 'en_US',
    interpolation: {
      escapeValue: false 
    }
  } );

export default i18n;