import { IState, IDataMaintainanceAction } from '../../../types';
import { EDataTableType } from '../../data/Constants';
import { updateMaintenanceData, updateMaintenanceSearchData } from '../../services/DataHelperFunctions';

export const administrationActions = {
  SETUSERS: 'administrationActions-SETUSERS',
  SETROLES: 'administrationActions-SETROLES',
  SETAPIKEYS: 'administrationActions-SETAPIKEYS',
  SETAPPLICATIONS: 'administrationActions-SETAPPLICATIONS'
}

const reducerActionMapping = {
  [administrationActions.SETUSERS]: EDataTableType.Users,
  [administrationActions.SETROLES]: EDataTableType.Roles,
  [administrationActions.SETAPIKEYS]: EDataTableType.ApiKeys,
  [administrationActions.SETAPPLICATIONS]: EDataTableType.Applications
}

const initialState = {
  data: [],
  users: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  },
  roles: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  },
  apikeys: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  },
  applications: {
    data:{},
    totalCount:'',
    currentPage:'',
    searchKey:'',
    searchData:{},
    recordsPerPage:0
  }
}


export const administrationReducer = ( state = initialState, action:IDataMaintainanceAction = { type: '' } ) => {
  if( Object.keys( reducerActionMapping ).includes( action.type ) ) {
    if( !action.searchKey ) {
      return updateMaintenanceData( state, action, reducerActionMapping[action.type] )
    } else {
      return updateMaintenanceSearchData( state, action, reducerActionMapping[action.type] )
    }
  }
  return state;
}
export const administrationState = ( state: IState ) => ( { administration: state.administartion } );