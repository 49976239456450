import { Dispatch } from 'react';
import { combineReducers } from 'redux';
import { busyDispatcher, busyReducer, userSettingsReducer, errorReducer, userReducer, roleReducer, applicationSettingsReducer, apikeyReducer, alertMessageReducer, dataMaintainanceReducer, dialogReducer, administrationReducer, featureFlagReducer, applicationReducer } from '.';
import { IAction } from '../../types';
import ServiceExtension from './ServiceExtension';
import { configureStore } from '@reduxjs/toolkit'

const onBusy = ( dispatchFn: Dispatch<IAction>, busy: boolean ) => busy
  ? busyDispatcher( dispatchFn ).setBusy()
  : busyDispatcher( dispatchFn ).setNotBusy();

export const serviceExtension = new ServiceExtension( onBusy );

const reducer = combineReducers( {
  busy: busyReducer,
  userSettings: userSettingsReducer,
  error: errorReducer,
  users: userReducer,
  roles: roleReducer,
  applicationSettings: applicationSettingsReducer,
  apikeys: apikeyReducer,
  alertMessage: alertMessageReducer,
  dataMaintainance: dataMaintainanceReducer,
  dialogData: dialogReducer,
  administartion: administrationReducer,
  applications: applicationReducer,
  featureFlags: featureFlagReducer
} )

export const AppStore = configureStore (
  {
    reducer,
    middleware:[ store => dispatchFn => action => serviceExtension.dataService( store, dispatchFn, action ) ]
  } )