import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { connect } from 'react-redux';
import { dataMaintainanceState, dataMaintainanceDispatcher } from '../../store';
import { displayMessage } from '../DisplayMessage';

export const $ProductCatalog = ( props: { token: any; getProductCatalog: any; } ) => {
  const {t} = useTranslation();

  if( props.error.code && props.error.type === EApiType.ProductCatalogKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.productCatalog' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.ProductCatalog }
      getDataList = { props.getProductCatalog }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}

export const ProductCatalog = connect( dataMaintainanceState, dataMaintainanceDispatcher )( $ProductCatalog ) ;