import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dataMaintainanceDispatcher, dataMaintainanceState, alertMessageState, alertMessageDispatcher, Combine, errorState, errorDispatcher, AppStore } from '../../store';
import { Box,Button, TextField, Checkbox, FormControlLabel, Collapse, Alert, AlertTitle } from '@mui/material';
import { displayMessage } from '../DisplayMessage';
import { EActionType, EAction } from '../../data/Constants';
import { useAuth } from 'oidc-react';

const nameRegex = /^[A-Za-z0-9]+(_[A-Za-z0-9]+)*$/;
const languageCodeRegex = /^[a-zA-Z]{2}-[a-zA-Z]{2}$/;

function callLanguageApi( manipulationType: string, props, formData, t: ( message: string ) => void ) {
  delete formData['id'];
  if( manipulationType === EActionType.Edit ) {
    props.updateLanguage( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim()} ).then( res=>{
      handleApiResponse( res,props,t( 'messages.fail.update' ),t( 'messages.success.update' ) );
    } )
  }else{
    props.createLanguage( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim()} ).then( res=>{
      handleApiResponse( res,props,t( 'messages.fail.create' ),t( 'messages.success.create' ) );
    } )
  }
}

function handleApiResponse( res,props,failMessage:string,successMessage:string ) {
  const languages = AppStore.getState().dataMaintainance.languages;
  if( res.error ) {
    const message = res.error.message ? res.error.message : failMessage
    props.setAlertMessage( {show:true,message:message,type:'error'} )
  }else{
    props.getLanguages( props.token,languages.currentPage,languages.recordsPerPage,languages.searchKey )
    props.setAlertMessage( {show:true,message:successMessage,type:'success'} )
    props.closeDialog( false )
  }
}

const loadFormData = async ( props, setInitialLanguageData, setFormData ) => {
  let languageDetails = await props.getLanguageDetails( props.token, props.id );
  languageDetails = languageDetails.data[0];
  if( !languageDetails ) {
    return null;
  }   
  setInitialLanguageData( languageDetails );
  setFormData( languageDetails );
}

const setAlertMessage = ( props, setDisplayAlertMessage ) => {
  if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }
}

const formValidation = ( formValidationProps ) => {
  const { formData, initialLanguageData, setDisplayNameErrorMessage, setDisplayCodeErrorMessage, setDisableButton } = formValidationProps;
  const name = formData.name.trimEnd();
  const code = formData.code.trimEnd();
  const validName = name.length > 2 && nameRegex.test( name );
  const validCode = code.length === 5 && languageCodeRegex.test( code );
  const detailsUpdated = JSON.stringify( initialLanguageData ) !== JSON.stringify( {...formData,name } )
  setDisplayNameErrorMessage( !validName );
  setDisplayCodeErrorMessage( !validCode );
  setDisableButton( !validName || !detailsUpdated || !validCode )
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;    
  const value = name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

const ActionButtons = ( actionButtonsProps )=>{
  const { props, manipulationType, disableButton, handleSubmit, setDisplayAlertMessage, t } = actionButtonsProps;
  return <>
    <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
      { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
    </Button>
    <Button size="medium" onClick={ ()=> {
      props.closeDialog( false );
      props.resetAlertMessage( );
      props.resetError(); 
      setDisplayAlertMessage( false ); 
    } } className="text-capitalize"
    >{ t( 'button.cancel' ) } </Button>
  </>
}

export const $LanguageManipulation = ( props ) => {
  const {t} = useTranslation();
  const auth = useAuth();
  const manipulationType = props.type; //To get the type of manipulation being performed
  const [formData, setFormData] = useState( {'code':'','name':'','description':'','isActive':false} );
  const [initialLanguageData, setInitialLanguageData] = useState( {'code':'','name':'','description':'','isActive':false} );
  const [disableButton, setDisableButton] = useState( true );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState( false );
  const [displayCodeErrorMessage, setDisplayCodeErrorMessage] = useState( false );

  useEffect( ()=>{   
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props,setInitialLanguageData,setFormData )
    }
  },[] )

  useEffect( ()=>{
    formValidation( { formData, initialLanguageData, setDisplayNameErrorMessage, setDisplayCodeErrorMessage, setDisableButton } );
  } )

  useEffect( () => {     
    setAlertMessage( props, setDisplayAlertMessage );
  }, [props.error] ) 
  
  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => { 
    if ( reason === 'clickaway' ) {
      return;
    }
    props.resetAlertMessage( );
    props.resetError();
    setDisplayAlertMessage( false );
  };
  
  const handleSubmit = ( event ) => {
    event.preventDefault();
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false );
    callLanguageApi( manipulationType,props,formData,t )
  }

  return <>
    <Box className="manipulationBox pt-1">     
      <Box className="manipulationForm">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ handleClose }>
            <AlertTitle>{displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
          </Alert>
        </Collapse>
        <TextField name="code" label={ t( 'labels.code' ) } required variant="outlined" size="small" fullWidth value={ formData.code || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } } disabled={ manipulationType === EActionType.Edit }
        /><br/>
        <Collapse in={ displayCodeErrorMessage && formData.code.length > 0 }>
          <Alert className="errorMessage" severity="error">
            <AlertTitle>{t( 'messages.codeErrorMessage' )} </AlertTitle>
          </Alert>
        </Collapse>
        <br/>
        <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" fullWidth value={ formData.name || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } } disabled = { manipulationType === EActionType.Edit && !formData.isActive }
        /><br/>
        <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
          <Alert className="errorMessage" severity="error">
            <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
          </Alert>
        </Collapse>
        <br/>
        <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" fullWidth value={ formData.description || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } } disabled = { manipulationType === EActionType.Edit && !formData.isActive }
        /><br/>
        <br/>
        {!initialLanguageData.isActive && manipulationType === EActionType.Edit ?
          <> <FormControlLabel
            value={ formData.isActive }
            checked={ !!formData.isActive }
            control={ <Checkbox /> }
            label={ t( 'labels.isActive' ) }
            labelPlacement="end"
            onChange={ ( e )=>{
              handleChange( e, setFormData )
            } }
            name="isActive"
          />
          <br/></> : null
        }
        <Box className="formActionButtons">
          { ActionButtons( { props, manipulationType, disableButton, handleSubmit, setDisplayAlertMessage, t } ) }          
        </Box>
      </Box>
    </Box>
  </>
}

export const LanguageManipulation = connect( Combine( dataMaintainanceState,alertMessageState,errorState ),Combine( dataMaintainanceDispatcher,alertMessageDispatcher,errorDispatcher ) ) ( $LanguageManipulation ) ;