import { Dispatch } from 'react';
import { SessionStore } from '../../services/SessionStore';
import { IApplicationSettingsAction, IState, IApplicationSettings } from '../../../types';
export const applicationSettingsActions = {
  SET: 'applicationSettings-SET',
  RESET: 'applicationSettings-RESET',
  GET: 'applicationSettings-GET'
}
const sessionStoreKey = 'application-settings';
const defaultApplicationSettings:IApplicationSettings = {
  showSectionTabs: true,
  activeTab: 0,
  forceShowSectionTabs: false,
  pageSize: {
    isExtraSmall: false,
    isSmall: false, 
    isMedium: false,
    isLarge: false,
    isExtraLarge: false
  }
}

const getDefaultSettings = () => {
  let storedSettings = SessionStore.get( sessionStoreKey );
  
  if( !storedSettings ) {
    storedSettings = defaultApplicationSettings;
  } else {
    let flag = false;
    for( const key in defaultApplicationSettings ) {
      if( !( key in storedSettings ) ) {
        flag = true;
        //set default settings if stored settings are missing any property
        storedSettings[key] = defaultApplicationSettings[key as keyof IApplicationSettings]; // add missing property in stored settings with default value
        
      }
    }
    if( flag ) { // if property is missing then only update it in session store
      SessionStore.set( sessionStoreKey, storedSettings );
    }
  }  
  return { ...storedSettings, forceShowSectionTabs: false, forceShowSummary: false };
}

export const applicationSettingsDispatcher = ( dispatch: Dispatch<IApplicationSettingsAction> ) => ( {
  setApplicationSettings: ( applicationSettings: IApplicationSettings ) => dispatch( { type: applicationSettingsActions.SET, applicationSettings } ),
  resetApplicationSettings: () => dispatch( { type: applicationSettingsActions.RESET } ),
  getApplicationSettings:( token: string )=>dispatch( { type: applicationSettingsActions.GET,token } )
} )

export const applicationSettingsReducer = ( state = getDefaultSettings(), action: IApplicationSettingsAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case applicationSettingsActions.SET:
      newState = { ...state, ...action.applicationSettings };
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    case applicationSettingsActions.RESET:
      newState = { ...state, ...defaultApplicationSettings };
      SessionStore.set( sessionStoreKey, newState );
      return newState;
    case applicationSettingsActions.GET:
      return newState;
    default:
      return state;
  }
};

export const applicationSettingsState = ( state: IState ) => ( { applicationSettings: state.applicationSettings } );