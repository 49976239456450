import { Dispatch } from 'react';
import { IDialogAction, IState } from '../../../types';

export const DialogActions = {
  SET: 'dialog-SET',
  RESET: 'dialog-RESET'
}
  
const defaultDialog = {
  show:false,
  message:'',
  type:null
} 
export const dialogDispatcher = ( dispatch: Dispatch<IDialogAction> ) => ( {
  setDialogMessage: ( dialogDataObj ) => dispatch( { type: DialogActions.SET, dialogData:dialogDataObj } ),
  resetDialogMessage: () => dispatch( { type: DialogActions.RESET } ),
} )
  
export const dialogReducer = ( state = defaultDialog, action:IDialogAction = { type: '' } ) => {
  let newState = {...state};
  switch ( action.type ) {
    case DialogActions.SET:
      newState = { ...state, ...action.dialogData };
      return newState;
    case DialogActions.RESET:
      newState = { ...state, ...defaultDialog };
      return newState;
    default:
      return state;
  }
};
  
export const dialogState = ( state: IState ) => ( { dialogData: state.dialogData } );