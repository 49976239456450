import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField,Collapse, Alert, AlertTitle,Checkbox,SelectChangeEvent, FormControlLabel, Grid,Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { apikeyDispatcher, apikeyState, alertMessageState, alertMessageDispatcher, Combine, dialogState, dialogDispatcher, errorState, errorDispatcher, AppStore, featureFlagDispatcher } from '../../../store';
import { EActionType,ECommonDialogType,EAction } from '../../../data/Constants';
import { IApiKeyManipulationProps } from '../../../../types';
import { displayMessage } from '../../DisplayMessage';
import { useAuth } from 'oidc-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

function callApiKeyApi( manipulationType:string,props:IApiKeyManipulationProps,formData,t:( message:string ) => void ) {
  if( manipulationType === EActionType.Edit ) {
    props.updateApiKey( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim(),userEmail:formData.userEmail === '' ? null : formData.userEmail,supportDetails:formData.supportDetails } ).then( res=>{
      handleApiResponse( res,props,t( 'messages.fail.update' ),t( 'messages.success.update' ),'edit' );
    } )
  }else{
    props.createApiKey( props.token,{...formData,name:formData.name.trimEnd(),description:formData.description.trim(),supportDetails: formData.supportDetails } ).then( res=>{
      handleApiResponse( res,props,t( 'messages.fail.create' ),t( 'messages.success.create' ),'create' );
    } )
  }
}

function handleApiResponse( res,props:IApiKeyManipulationProps,failMessage:string,successMessage:string,type:string ) {
  const apikeys = AppStore.getState().administartion.apikeys;
  if( res.error ) {
    const message = res.error.message ? res.error.message : failMessage
    props.setAlertMessage( {show:true,message:message,type:'error'} )
  }else if( type === 'edit' ) {
    const msg = `${successMessage}`;
    props.setAlertMessage( {show:true,message:msg,type:'success'} );
    props.getApiKeys( props.token,apikeys.currentPage,apikeys.recordsPerPage,apikeys.searchKey );
    props.closeDialog( false )
  }else if( type === 'create' ) {
    props.setDialogMessage( {show:true,message:res.data,type:ECommonDialogType.ApiKeyCreateSuccess} );
    props.getApiKeys( props.token,apikeys.currentPage,apikeys.recordsPerPage,apikeys.searchKey );
  }
}

const handleChannelChange = ( event: SelectChangeEvent, setFormData, formData ) => {  
  const tempFormData = JSON.parse( JSON.stringify( formData ) )
  if ( event.target.checked ) {
    tempFormData.channels.push( Number( event.target.value ) )
  } else {
    tempFormData.channels.splice( tempFormData.channels.findIndex( ( val ) => val === Number( event.target.value ) ),1 )
  }
  setFormData( tempFormData )
}

const handlePropertyChange = ( event: SelectChangeEvent, setFormData, formData ) => {
  const tempFormData = JSON.parse( JSON.stringify( formData ) )
  if ( event.target.checked ) {
    tempFormData.properties.push( event.target.value )
  } else {
    tempFormData.properties.splice( tempFormData.properties.findIndex( ( val ) => val === event.target.value ), 1 )
  }
  setFormData( tempFormData )
}

const setFeatureFlagDetails = async( props, setFeatureFlags ) =>{
  const flags = await props.getAllFeatureFlags( props.token );
  setFeatureFlags( flags.data )
}

const loadFormData = async ( props:IApiKeyManipulationProps, setFormData, setInitialApiKeyData ) => {
  let apikeyDetails = await props.getApiKeyDetails( props.token,props.id )
  if( !apikeyDetails.data ) {
    return null;
  }
  apikeyDetails = apikeyDetails.data;
  const updatedChannels = [];

  const findChannels = props.apikeys.channels.filter( prod => {
    return apikeyDetails.channels.find( val => { 
      return prod.name === val
    } )
  } )

  findChannels.forEach( ( val,index ) => {
    if( updatedChannels.includes( val.id ) === false ) {
      updatedChannels.push( val.id )
    }
  } )

  const initData = {...apikeyDetails,
    'channels': updatedChannels,
    'supportDetails': apikeyDetails.supportDetails ? apikeyDetails.supportDetails : {'message':'','url':null},
    privileges: apikeyDetails.privileges ? apikeyDetails.privileges : [],
    properties: apikeyDetails.properties ? apikeyDetails.properties : [],
    featureFlags: apikeyDetails.featureFlags ? apikeyDetails.featureFlags : []
  }

  setFormData( JSON.parse( JSON.stringify( initData ) ) )
  setInitialApiKeyData( JSON.parse( JSON.stringify( initData ) ) )
}

const addAlertMessage = ( props,setDisplayAlertMessage ) => {
  if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }
}

const formValidations = ( formValidationsProps ) => {
  const { initialApiKeyData, formData, setDisplayNameErrorMessage, setDisplayUrlErrorMessage, setDisplaySupportUrlErrorMessage, setDisableButton } = formValidationsProps;
  formData.privileges.forEach( privilege => {
    privilege.accessTypes.sort( ( a,b ) => {
      return a - b;
    } )
  } )
  const name = formData.name.trimEnd();
  const validName = name.length > 2 && /^[A-Za-z0-9]+( [A-Za-z0-9]+)*$/.test( name ); 
  const UrlRegex = /^(https?|ftp):\/\/\w+(\.\w+)*(:\d+)?\/?(\/[.\w]*)*$/
  const validApplicationUrl = UrlRegex.test( formData.applicationUrl );
  formData.channels.sort( );
  const detailsUpdated = JSON.stringify( initialApiKeyData ) !== JSON.stringify( {...formData,name} );
  const validChannels = formData.channels.length > 0;
  const validSupportUrl = formData.supportDetails.url && formData.supportDetails.url.length > 0 ? UrlRegex.test( formData.supportDetails.url ) : true;

  setDisplayNameErrorMessage( !validName )
  setDisplayUrlErrorMessage( !validApplicationUrl )
  setDisplaySupportUrlErrorMessage( !validSupportUrl )
  setDisableButton( !validName || !validApplicationUrl || !validChannels || !validSupportUrl || !detailsUpdated || formData.privileges.length < 1 )
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;
  const value = name === 'accessType' ? Number( event.target.value ) : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )
}

const handleSupportDetailsChange = ( event, formData, setFormData )=>{
  const name = event.target.name;
  const supportDetails = { ...formData.supportDetails };
  supportDetails[name] = name === 'url' && !event.target.value.length ? null : event.target.value;
  setFormData( values => ( { ...values, supportDetails: supportDetails } ) )
}

const checkAccess = ( row, id, formData )=>{
  const rec = formData.privileges?.find( p => p.resource === row['id'] );
  let result = false;
  if( rec ) {
    result = row['accessTypes'].find( r =>rec['accessTypes']?.indexOf( id ) >= 0 ) ? true : false;
  }
  return result;
}

const handlePrivilegeChange = ( event, privileges, formData, setFormData )=>{
  const oldPrivileges = [ ...formData.privileges ];
  const index = oldPrivileges.findIndex( prev=>prev['resource'] === Number( event.target.id ) );
  if( event.target.checked ) {
    const totalPrivileges = [].concat( privileges ).map( ( { group,privilegeAccessAssociations } ) => {
      return privilegeAccessAssociations.map( x =>{
        return {...x,group}
      } )
    } ).flat( 1 );

    const row = totalPrivileges.find( cat=> cat['id'] === Number( event.target.id ) );

    if( index >= 0 ) {
      oldPrivileges[index].accessTypes = row.accessTypes.map( a => a.id );
    } else{
      oldPrivileges.push( {
        'resource': Number( event.target.id ),
        'group': row.group,
        'accessTypes':row.accessTypes.map( a => a.id )
      } )
    }
    setFormData( {...formData, privileges:oldPrivileges} )
  } else{
    oldPrivileges.splice( index,1 );
    setFormData( {...formData, privileges:oldPrivileges} )
  }
}

const handlePermissionChange = ( event, privileges, formData, setFormData )=>{
  const oldPrivileges = [ ...formData.privileges ];
  const resourceId = event.target.id.split( '#' )[0];
  const permissionId = Number( event.target.id.split( '#' )[1] );
  const index = oldPrivileges.findIndex( prev=>prev['resource'] == resourceId );
  const group = privileges.find( privilege => privilege.privilegeAccessAssociations.find( resource => resource.id == resourceId ) ).group //to find the group of permission of a privilege

  if( event.target.checked ) {
    if( index >= 0 ) {
      oldPrivileges[index].accessTypes.push( permissionId );
    }else{
      oldPrivileges.push( {
        'resource': Number( resourceId ),
        'accessTypes': [ permissionId ],
        'group': group
      } )
    }
  }else{
    const permissionIndex = oldPrivileges[index].accessTypes.indexOf( permissionId ) ;
    if( oldPrivileges[index].accessTypes.length <= 1 ) {
      oldPrivileges.splice( index,1 )
    } else{
      oldPrivileges[index].accessTypes.splice( permissionIndex,1 )
    } 
  }  
  setFormData( { ...formData, privileges:oldPrivileges } )
}

const handleFeatureFlagChange = ( event, flag, initialApiKeyData, formData, setFormData ) => {
  const index = formData.featureFlags.findIndex( formDataFlag => formDataFlag.name === flag.flagName );
  const initialFields = {
    isActive: false,
    editable: false,
    name: '',
    visible: false
  }

  if( !event.target.checked ) {
    if( index >= 0 && initialApiKeyData.featureFlags.find( formDataFlag => formDataFlag.name === flag.flagName ) ) {
      const updatedFlags = [...formData.featureFlags];
      updatedFlags[index][event.target.name] = false;
      setFormData( { ...formData, featureFlags:updatedFlags } );
    } else if( index >= 0 ) {
      const updatedFlags = [...formData.featureFlags];
      updatedFlags[index][event.target.name] = false;
      if( !Object.values( updatedFlags[index] ).includes( true ) ) { //remove the flag if non of the properties/actions are true
        updatedFlags.splice( index,1 );
      }
      setFormData( { ...formData, featureFlags:updatedFlags } );
    }
  } else{
    if( index >= 0 ) {
      const updatedFlags = [...formData.featureFlags];
      updatedFlags[index][event.target.name] = true;
      setFormData( { ...formData, featureFlags:updatedFlags } );      
    } else{
      const updatedFlags = [...formData.featureFlags];
      updatedFlags.push( {...initialFields, name: flag.flagName, [event.target.name]: true} )
      setFormData( { ...formData, featureFlags:updatedFlags } );
    }
  }  
}

const renderFeatureFlagsTable = ( renderFeatureFlagsTableProps ) =>{
  const { featureFlags, initialApiKeyData, formData, setFormData, t } = renderFeatureFlagsTableProps;

  return <TableContainer component={ Paper } className="featureFlagsTable">
    <Table stickyHeader aria-label="featureFlags table">

      <TableHead>
        <TableRow key="featureFlagsHeader">
          <TableCell key="applicationName" style={ { width: '15%' } }>{t( 'labels.applicationName' )}</TableCell>
          <TableCell key="page" style={ { width: '22.5%' } }>{t( 'labels.page' )}</TableCell>
          <TableCell key="flagName" style={ { width: '22.5%' } }>{t( 'labels.flagName' )}</TableCell>
          <TableCell key="isActive" style={ { width: '10%' } }>{t( 'labels.isActive' )}</TableCell>
          <TableCell key="isVisible" style={ { width: '10%' } }>{t( 'labels.isVisible' )}</TableCell>
          <TableCell key="isEditable" style={ { width: '10%' } }>{t( 'labels.isEditable' )}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>

        {featureFlags?.map( application => {
          const activeFlags = application.featureFlags?.filter( flag => flag.isActive ).length;
          // Render only the Flags that are active and ignore the applications with no active flags
          return activeFlags > 0 && <>
            <TableRow key={ application.applicationName }>
              <TableCell rowSpan={ activeFlags + 1 } sx={ { overflowWrap:'anywhere' } }>
                {application.applicationName}
              </TableCell>
            </TableRow>
            {application.featureFlags?.map( flag => {
              return flag.isActive && <TableRow hover key={ '' + flag.page + flag.flagName }>
                <TableCell key="page" sx={ { overflowWrap:'anywhere' } }>{flag.page}</TableCell>

                <TableCell key="flagName" sx={ { overflowWrap:'anywhere' } }>{flag.flagName}</TableCell>

                <TableCell key="isActive" padding="checkbox" align="center">{flag.isActive ? <FormControlLabel
                  value={ flag.flagName }
                  checked={ !!formData.featureFlags?.find( formDataFlag => formDataFlag.name === flag.flagName )?.isActive }
                  control={ <Checkbox disabled={ !formData.isActive }/> }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleFeatureFlagChange( e, flag, initialApiKeyData, formData, setFormData )
                  } }
                  name="isActive"
                  label=""
                /> : <FormControlLabel
                  value="-"
                  control={ <></> }
                  name="isActive"
                  label="-"
                  className="cursor-default "
                />}
                </TableCell>

                <TableCell key="isVisible" padding="checkbox" align="center">{flag.isVisible ? <FormControlLabel
                  value={ flag.flagName }
                  checked={ !!formData.featureFlags?.find( formDataFlag => formDataFlag.name === flag.flagName )?.visible }
                  control={ <Checkbox disabled={ !formData.isActive }/> }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleFeatureFlagChange( e, flag, initialApiKeyData, formData, setFormData )
                  } }
                  name="visible"
                  label=""
                /> : <FormControlLabel
                  value="-"
                  control={ <></> }
                  name="isActive"
                  label="-"
                  className="cursor-default "
                />}
                </TableCell>

                <TableCell key="isEditable" padding="checkbox" align="center">{flag.isEditable ? <FormControlLabel
                  value={ flag.flagName }
                  checked={ !!formData.featureFlags?.find( formDataFlag => formDataFlag.name === flag.flagName )?.editable }
                  control={ <Checkbox disabled={ !formData.isActive }/> }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleFeatureFlagChange( e, flag, initialApiKeyData, formData, setFormData )
                  } }
                  name="editable"
                  label=""
                /> : <FormControlLabel
                  value="-"
                  control={ <></> }
                  name="isActive"
                  label="-"
                  className="cursor-default "
                />}
                </TableCell>
              </TableRow>
            } )}
          </>
        } )

        }

      </TableBody>

    </Table>
  </TableContainer>
}

const renderPrivilegesTable = ( renderPrivilegesTableProps ) => {
  const { formData, setFormData, t } = renderPrivilegesTableProps;
  const privilegesResponse = [ ...renderPrivilegesTableProps.privileges ];
  return <TableContainer component={ Paper } className="api-data-table">
    <Table stickyHeader aria-label="permissions table">

      <TableHead>
        <TableRow key="permissionsHeader">
          <TableCell key="group" style={ { width: '17.5%', padding:'2%', } }>{t( 'labels.group' )}</TableCell>
          <TableCell key="resource" style={ { width: '17.5%', padding:'2%', } }>{t( 'labels.resource' )}</TableCell>
          <TableCell key="permission" style={ { width: '65%', padding:'2%', } }>{t( 'labels.permissions' )}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {privilegesResponse.map( ( response ) =>{
          return response.privilegeAccessAssociations.map( ( privileges, index )=>{
            return <TableRow
              sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
              hover            
              role="checkbox"
              tabIndex={ -1 }
              key={ privileges.id }
            >
              <TableCell key="group" padding="checkbox" sx={ {paddingLeft:'2%'} } > {response.group} </TableCell>

              <TableCell key="resource" padding="checkbox" sx={ { paddingLeft:'2%' } } >
                <FormControlLabel
                  value={ privileges['resource'] }
                  control={ <Checkbox id={ privileges.id + '' } disabled={ !formData.isActive } /> }
                  label={ privileges['resource'] }
                  labelPlacement="end"
                  checked={ formData.privileges?.find( p => p.resource === privileges.id )?.accessTypes.length === privileges['accessTypes'].length }
                  onChange={ ( event )=>{
                    handlePrivilegeChange( event, privilegesResponse, formData, setFormData ) 
                  } }                     
                /></TableCell>
      
              <TableCell key="access" padding="checkbox" sx={ { paddingLeft:'2%', overflowWrap:'anywhere' } }>
                <Grid container spacing={ 1 }>
                  {privileges['accessTypes'].map( ( c ) => <Grid item xs={ 4 } key={ c.id }>
                    <FormControlLabel
                      value={ privileges['resource'] }
                      control={ <Checkbox id={ privileges['id'] + '#' + c['id'] } disabled={ !formData.isActive }/> }
                      label={ c['name'] }
                      labelPlacement="end"
                      checked={ checkAccess( privileges, c['id'], formData ) }
                      onChange={ ( event )=>{
                        handlePermissionChange( event, privilegesResponse, formData, setFormData ) 
                      } }
                    />
                  </Grid> )}</Grid>
              </TableCell>

            </TableRow>
          } )    
        } )
        }
      </TableBody>
    </Table>
  </TableContainer>
}

const ActionButtons = ( actionButtonsProps ) => {
  const { props, disableButton, manipulationType, handleSubmit, setDisplayAlertMessage, t } = actionButtonsProps;
  
  return <>
    <Button variant="contained" size="medium" onClick={ handleSubmit }
      className="text-capitalize"
      disabled={ disableButton }
    >
      { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
    </Button>
    <Button size="medium" onClick={ ()=>{
      props.closeDialog( false );
      props.resetAlertMessage( );
      props.resetError(); 
      setDisplayAlertMessage( false ); 
    } } className="text-capitalize"
    >{ t( 'button.cancel' ) } </Button>
  </>
}

export const $ApiKeyManipulation = ( props:IApiKeyManipulationProps ) => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [formData, setFormData] = useState( {'name':'', 'description':'', 'key':'', 'applicationUrl':'','isActive':true, 'channels':[], 'userEmail':'', 'supportDetails':{'message':'','url':null}, 'features':{'isOptional':true}, 'privileges':[],'properties':[], featureFlags:[]} );
  const [initialApiKeyData, setInitialApiKeyData] = useState( {'name':'', 'description':'', 'key':'', 'applicationUrl':'','isActive':true, 'channels':[], 'userEmail':'', 'supportDetails':{'message':'','url':null}, 'features':{'isOptional':true}, 'privileges':[],'properties':[], featureFlags:[]} )
  const [disableButton, setDisableButton] = useState( true );
  const [displayNameErrorMessage,setDisplayNameErrorMessage] = useState( false );
  const [tabValue,setTabValue] = useState( 'Api' );
  const [displayUrlErrorMessage,setDisplayUrlErrorMessage] = useState( false );
  const [displaySupportUrlErrorMessage,setDisplaySupportUrlErrorMessage] = useState( false );
  const [displayAlertMessage,setDisplayAlertMessage] = useState( false );
  const [serviceUsersName, setServiceUsersName] = React.useState<string[]>( [] );
  const [channels, setChannels] = React.useState<[]>( [] );
  const [properties, setProperties] = React.useState<[]>( [] );
  const [privileges] = useState( props.apikeys.privileges );
  const [featureFlags, setFeatureFlags] = useState( [] );
    
  const manipulationType = props.type; //To get the type of manipulation being performed

  useEffect( ()=>{
    setChannels( props.apikeys.channels )
    setProperties( props.apikeys.properties )
    setServiceUsersName( props.apikeys.serviceUsers )
    setFeatureFlagDetails( props, setFeatureFlags )
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setFormData, setInitialApiKeyData );
    }
  },[] )
  
  useEffect( () => {     
    addAlertMessage( props, setDisplayAlertMessage );
  }, [props.error] ) 

  useEffect( ()=>{
    formValidations( { initialApiKeyData, formData, setDisplayNameErrorMessage, setDisplayUrlErrorMessage, setDisplaySupportUrlErrorMessage, setDisableButton } );
  } )

  const handleSubmit = ( event:any ) => {
    event.preventDefault();
    props.resetAlertMessage( );
    props.resetError(); 
    setDisplayAlertMessage( false );
    callApiKeyApi( manipulationType,props,formData,t )
  }

  if( !props.apikeys ) {
    return null;
  }

  const handleChangeCheck = ( event: SelectChangeEvent ) => {
    handleChannelChange( event, setFormData, formData )
  };

  const handlePropertyChangeCheck = ( event: SelectChangeEvent ) => {
    handlePropertyChange( event, setFormData, formData )
  };

  const handleTabChange = ( ...params:[React.BaseSyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    props.resetAlertMessage( );
    props.resetError();
    setDisplayAlertMessage( false );
  };

  return <Box className={ 'userManipulationTabs ' + ( tabValue === 'Features' ? 'overflowY-hidden' : '' ) }>
    <Collapse in={ displayAlertMessage } className="show-alert">
      <Alert className="errorMessage" severity="error" onClose={ handleClose }>
        <AlertTitle>{displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
      </Alert>
    </Collapse>
    <TabContext value={ tabValue || 'Api' }>
      <Box className="dialog-box">
        <TabList onChange={ handleTabChange }>
          <Tab className="dialog-tab text-capitalize"
            value="Api" label={ t( 'labels.apikeyDetails' ) }
          />
          <Tab className="dialog-tab text-capitalize" 
            value="Features" label={ t( 'labels.features' ) }
          />
          <Tab className="dialog-tab text-capitalize" 
            value="Properties" label={ t( 'labels.properties' ) }
          />
          <Tab className="dialog-tab text-capitalize" 
            value="SupportDetails" label={ t( 'labels.supportDetails' ) }
          />
          <Tab className="dialog-tab text-capitalize" 
            value="Permissions" label={ t( 'labels.permissions' ) }
          />
        </TabList>
      </Box>

      <TabPanel value="Api" className="personalDetailsTab pt-1" >
        <Box className="manipulationForm">            
          { manipulationType === EActionType.Edit ?
            <><TextField name="key" label={ t( 'labels.key' ) } variant="outlined" size="small" fullWidth value={ formData.key } disabled/><br/><br/></>
            : null
          }
          <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" fullWidth value={ formData.name } disabled={ !formData.isActive } onChange={ ( event ) => {
            handleChange( event, setFormData )
          } }
          /><br/>
          <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
            <Alert className="errorMessage" severity="error">
              <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
            </Alert>
          </Collapse>
          <br/>
          <TextField name="applicationUrl" label={ t( 'labels.applicationUrl' ) } required variant="outlined" size="small" fullWidth value={ formData.applicationUrl } disabled={ !formData.isActive } onChange={ ( event ) => {
            handleChange( event, setFormData )
          } }
          /><br/>
          <Collapse in={ displayUrlErrorMessage && formData.applicationUrl.length > 0 }>
            <Alert className="errorMessage" severity="error">
              <AlertTitle>{t( 'messages.urlErrorMessage' )} </AlertTitle>
            </Alert>
          </Collapse>
          <br/>
          <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" fullWidth value={ formData.description } disabled={ !formData.isActive } onChange={ ( event ) => {
            handleChange( event, setFormData )
          } }
          /><br/>
          <br/>

          <fieldset className="inputFieldset w-100">
            <legend className="inputLegend" id="userEmail">{t( 'labels.userEmail' ) }</legend>
            {serviceUsersName.length < 1 ? <p>{t( 'messages.noServiceUserAvailable' )}</p> :
              <RadioGroup
                aria-labelledby="userEmail"
                name="userEmail"
                value={ formData.userEmail } 
                onChange={ ( event ) => {
                  handleChange( event, setFormData )
                } }
              > <Grid container spacing={ 2 } className="pt-1">
                  {serviceUsersName.map( ( value:string ) => {
                    return <Grid item xs={ 8 } key={ value } className="pt-0"> <FormControlLabel value={ value } label={ value } control={ < Radio /> } disabled={ !formData.isActive }/></Grid>
                  } )
                  }
                </Grid>
              </RadioGroup>}
          </fieldset>
          <br/>

          <fieldset className="inputFieldset w-100">
            <legend className="inputLegend">{t( 'labels.channels' )} <span className="PhilipsRed">*</span> </legend>
            <br/>
            <Grid container spacing={ 5 } className="pt-1">
              {channels?.map( ( obj )=> {
                return <Grid item xs={ 4 } key={ obj.id } className="pt-0"> <FormControlLabel key={ obj.id } label={ obj.name } control={ < Checkbox onChange={ handleChangeCheck } disabled={ !formData.isActive } checked={ formData.channels.indexOf( obj.id ) > -1 } value={ obj.id } /> }/>  </Grid> 
              }
              )}
            </Grid>
          </fieldset>           
        </Box>
      </TabPanel>

      <TabPanel value="Features" sx={ {} } className="featureFlagsTab pt-0" >
        {renderFeatureFlagsTable( {featureFlags, initialApiKeyData, formData, setFormData, t } )}
      </TabPanel>

      <TabPanel value="Properties" sx={ {} } className="personalDetailsTab pt-0" >
        <Box className="propertiesForm" >
          <fieldset className="inputFieldset w-100">
            <legend className="inputLegend">{t( 'labels.properties' )}  </legend>
            <br />
            <Grid container spacing={ 5 } className="pt-1">
              {properties?.map( ( property ) => {
                return <Grid item xs={ 6 } key={ property.code } className="pt-0"> <FormControlLabel className="overflowWrap-anywhere" key={ property.code } label={ property.code + ' (' + property.name + ')' } control={ < Checkbox onChange={ handlePropertyChangeCheck } disabled={ !formData.isActive } checked={ formData.properties.indexOf( property.code ) > -1 } value={ property.code } /> } />  </Grid>
              }
              )}
            </Grid>
          </fieldset>
        </Box>
      </TabPanel>

      <TabPanel value="SupportDetails" sx={ {} } className="personalDetailsTab pt-1" >
        <Box className="supportDetailsForm" >
          <TextField name="message" label={ t( 'labels.message' ) } variant="outlined" size="small" fullWidth value={ formData.supportDetails.message } disabled={ !formData.isActive } onChange={ ( event )=>{
            handleSupportDetailsChange( event, formData, setFormData )
          } }
          /><br/>
          <TextField className="mt-1em" name="url" label={ t( 'labels.url' ) } variant="outlined" size="small" fullWidth value={ formData.supportDetails.url } disabled={ !formData.isActive } onChange={ ( event )=>{
            handleSupportDetailsChange( event, formData, setFormData )
          } }
          /><br/>
          <Collapse className="pt-1" in={ displaySupportUrlErrorMessage && formData.supportDetails.url }>
            <Alert className="errorMessage" severity="error">
              <AlertTitle>{t( 'messages.urlErrorMessage' )} </AlertTitle>
            </Alert>
          </Collapse>
        </Box>
      </TabPanel>

      <TabPanel value="Permissions" sx={ {} } className="permissionsTab pt-0" >
        { renderPrivilegesTable( { privileges, formData, setFormData, t } ) }
      </TabPanel>

    </TabContext>
    <br/>
    <Box className="formActionButtons">
      {ActionButtons( { props, disableButton, manipulationType, handleSubmit, setDisplayAlertMessage, t } )}
    </Box>
  </Box>
}
  
export const ApiKeyManipulation = connect( Combine( apikeyState, alertMessageState, dialogState, errorState ),
  Combine( apikeyDispatcher, alertMessageDispatcher, featureFlagDispatcher, dialogDispatcher, errorDispatcher ) ) ( $ApiKeyManipulation ) ;