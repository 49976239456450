import { Dispatch } from 'react';
import { IAction, IState } from '../../../types';

export const busyActions = {
  BUSY: 'busyActions-BUSY',
  NOTBUSY: 'busyActions-NOTBUSY'
}

export const busyDispatcher = ( dispatch: Dispatch<IAction> ) => ( {
  setBusy: () => dispatch( { type: busyActions.BUSY } ),
  setNotBusy: () => dispatch( { type: busyActions.NOTBUSY } )
} )

export const busyReducer = ( state = false, action: IAction = { type: '' } ) => {
  switch ( action.type ) {
    case busyActions.BUSY:
      return true;
    case busyActions.NOTBUSY:
      return false;
    default:
      return state;
  }
};

export const busyState = ( state: IState ) => ( { busy: state.busy } );