/**
 * Checks if the element has the given id or is child of an element with the given id
 * @param {HTMLElement} element the element to check
 * @param {string} id the id to search for
 * @param {boolean} exactMatch if true, the id needs to be equals otherwise the element id needs to include the given id
 * @returns {boolean} true if element has the id or is a child of an element with the id
 */
export function isChildOf( element: HTMLElement, id: string, exactMatch = true ) : boolean {
  if( exactMatch && element.id === id || !exactMatch && element.id.includes( id ) ) {
    return true;
  }
  
  if( !element.parentElement ) {
    return false;
  }
  
  return isChildOf( element.parentElement, id, exactMatch );
}
  
/**
   * Checks the element and it parents for the given id
   * @param {HTMLElement} element the element to check
   * @param {string} id the id to search for
   * @returns {string|undefined} the full id of the first parent element which includes the given id
   */
export function getFullParentIdOf( element: HTMLElement, id: string ) : string | undefined {
  if( element.id.includes( id ) ) {
    return element.id;
  }
  
  if( !element.parentElement ) {
    return undefined;
  }
  
  return getFullParentIdOf( element.parentElement, id );
}