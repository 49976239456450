import { Dispatch } from 'react';
import { IApiKeyAction,IState,IApiKey,IAccessType } from '../../../types';

export const apikeyActions = {
  APIKEYLIST: 'apikeyActions-APIKEYLIST',
  SETAPIKEYS: 'apikeyActions-SETAPIKEYS',
  CREATEAPIKEY:'apikeyActions-CREATEAPIKEY',
  DELETEAPIKEYS:'apikeyActions-DELETEAPIKEYS',
  UPDATEAPIKEY:'apikeyActions-UPDATEAPIKEY',
  GETACCESSTYPES:'apikeyActions-GETACCESSTYPES',
  SETACCESSTYPES:'apikeyActions-SETACCESSTYPES',
  GETCHANNELS:'apikeyActions-GETCHANNELS',
  GETSERVICEUSERS: 'apikeyActions-GETSERVICEUSERS',
  GETAPIKEYDETAILS: 'apikeyActions-GETAPIKEYDETAILS',
  SETPROPERTYLIST: 'apikeyActions-SETPROPERTYLIST',
  GETPROPERTYLIST: 'apikeyActions-GETPROPERTYLIST',
  GETPRIVILEGES: 'apikeyActions-GETPRIVILEGES',
  SETPRIVILEGES: 'apikeyActions-SETPRIVILEGES',
}
const initialState = {
  data:[],
  accessTypes:[],
  channels:[],
  serviceUsers: [],
  properties:[],
  privileges:[]
}
export const apikeyDispatcher = ( dispatch: Dispatch<IApiKeyAction> ) => ( {
  getApiKeys:( token: string, page:number, limit:number, searchCode?:string )=>dispatch( { type: 'apikeyActions-APIKEYLIST',token, page, limit, searchCode } ),
  createApiKey:( token: string,request:IApiKey )=>dispatch( { type: 'apikeyActions-CREATEAPIKEY',token,request } ),
  updateApiKey:( token: string,request:IApiKey )=>dispatch( { type: 'apikeyActions-UPDATEAPIKEY',token,request } ),
  deleteApiKeys:( token: string,request:string[] )=>dispatch( { type: 'apikeyActions-DELETEAPIKEYS',token,request } ),
  getAccessTypes:( token: string )=>dispatch( { type: 'apikeyActions-GETACCESSTYPES',token } ),
  getChannels:( token: string )=>dispatch( {type:'apikeyActions-GETCHANNELS',token} ),
  getServiceUsers:( token: string )=>dispatch( {type:'apikeyActions-GETSERVICEUSERS',token} ),
  getApiKeyDetails: ( token: string, id: string ) => dispatch( { type: 'apikeyActions-GETAPIKEYDETAILS', token, id } ),
  getPropertyList: ( token: string ) => dispatch( { type: 'apikeyActions-GETPROPERTYLIST', token } ),
  getPrivileges: ( token: string ) => dispatch( { type: 'apikeyActions-GETPRIVILEGES', token } )
} )

export const apikeyReducer = ( state = initialState, action: IApiKeyAction = { type: '' } ) => {
  switch ( action.type ) {
    case apikeyActions.SETAPIKEYS: 
      if( state.accessTypes.length > 0 ) {
        action.data?.forEach( ( k:IApiKey )=>{
          k.access = state.accessTypes?.find( ( act:IAccessType )=>act.id === k.accessType ).name
        } )
      }
      return { ...state, data: action.data };
    case apikeyActions.SETACCESSTYPES: 
      if( state.data.length > 0 && action.data ) {
        state.data.forEach( ( k:IApiKey )=>{
          k.access = action.data.find( ( act:IAccessType )=>act.id === k.accessType ).name
        } )
      }
      return { ...state, accessTypes: action.data };
    case apikeyActions.GETCHANNELS: 
      return { ...state, channels: action.data ? action.data : [] };
    case apikeyActions.GETSERVICEUSERS: 
      return { ...state, serviceUsers: action.data ? action.data : [] };
    case apikeyActions.SETPROPERTYLIST:
      return { ...state, properties: action.data ? action.data : [] };
    case apikeyActions.SETPRIVILEGES:
      return { ...state, privileges: action.data ? action.data : [] };
    default:
      return state;
  }
}
export const apikeyState = ( state: IState ) => ( { apikeys: state.apikeys } );