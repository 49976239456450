/**
 * @description Manages a store
 */
export class DataStore {
  store: Storage;
  serializer: ( x: any ) => string;
  deserializer: ( x: string ) => any;

  /**
   * @description Creates a new instance of the DataStore class
   * @param {Storage} store An object which represents a store 
   */
  constructor( store: Storage ) {
    this.store = store;
    this.serializer = x => JSON.stringify( x );
    this.deserializer = x => !x && x !== null ? undefined : JSON.parse( x );
  }

  /**
   * @description Gets the data which was set for the given unique identifier
   * @param {string} key A unique identifier
   * @returns {object} Returns the data which was set for the given unique identifier
   */
  get( key: string ) {
    const value = this.store.getItem( key );
    if ( !value ) {
      return value;
    }
    try {
      return this.deserializer( value );
    } catch ( exception ) {
      console.error( `Could not deserialize ${ key } with value: ${ value }. Exception: ${ exception }` );
      return null;
    }
  }

  /**
   * @description Sets the value, which can be accessed by using the unique identifier
   * @param {string} key A unique identifier 
   * @param {object} value The data which should be stored 
   * @returns {undefined}
   */
  set( key: string, value: any ) {
    this.store.setItem( key, this.serializer( value ) );
  }

  /**
   * @description Removes the stored data, which uses the unique identifier
   * @param {string} key A unique identifier
   * @returns {undefined}
   */
  remove( key: string ) {
    this.store.removeItem( key );
  }

  /**
   * @description Removes all data from the store
   * @returns {undefined}
   */
  clear() {
    this.store.clear();
  }
}