import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceTable } from '../Layouts/DataTable';
import { EApiType, EDataMaintenanceType, EModuleType } from '../../data/Constants';
import { connect } from 'react-redux';
import { dataMaintainanceState, dataMaintainanceDispatcher } from '../../store';
import { ILanguageAssociationProps } from '../../../types';
import { displayMessage } from '../DisplayMessage';

export const $LanguageAssociation = ( props:ILanguageAssociationProps ) => {
  const {t} = useTranslation();

  if( props.error.code && props.error.type === EApiType.LanguageAssociationKey ) {
    return <h4>
      {displayMessage( props.error,t,'',props.auth )}
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'header.languageAssociation' )} </h3>
    <MaintenanceTable
      token={ props.token }
      type={ EDataMaintenanceType.LanguageAssociation }
      getDataList = { props.getLanguageAssociation }
      moduleType = { EModuleType.Datamaintainance }
    />
  </>
}

export const LanguageAssociation = connect( dataMaintainanceState, dataMaintainanceDispatcher )( $LanguageAssociation ) ;