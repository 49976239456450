import React from 'react';
import { EActionType,EHttpCode } from '../data/Constants';
import { IError } from '../../types';

export const displayMessage = ( error:IError,t:( arg:string,arg2?:object ) => string,manipulationType:string,auth:any ) =>{ 
  let message = '';
  if( manipulationType ) {
    message = manipulationType === EActionType.Create ? t( 'messages.fail.create' ) : t( 'messages.fail.update' );
  }
  switch ( error.code ) {
    case EHttpCode.Status401: return <span > { message + ' ' + t( 'messages.session' )} <a style={ {'cursor':'pointer','textDecorationLine':'underline'} } onClick={ () => {
      auth.signIn();
    } }
    > { t( 'messages.login' ) }</a></span>
    case EHttpCode.Status204: return <span >{error.message ? error.message : message + ' ' + t( 'messages.error.204' )}</span>
    case EHttpCode.Status400: return <span >{message + ' ' }  {error.message ? error.message : t( 'messages.error.400' )}</span>
    case EHttpCode.Status404: return <span >{error.message ? error.message : message + ' ' + t( 'messages.error.404' )}</span>
    case EHttpCode.Status403: return <span>{message + ' ' + t( 'messages.error.403' ) }</span>
    default:
      return <span >{message + ' ' + t( 'messages.error.500' )}</span>;
  }
}