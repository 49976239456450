import { Alert, AlertTitle, Box,Button,Checkbox,Collapse,FormControlLabel,Paper,Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alertMessageDispatcher, alertMessageState, Combine, roleDispatcher, roleState, userDispatcher, userState, errorState, errorDispatcher, AppStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { foreignKeysForTable,EActionType,RecordLength,TableHeaders,EAction } from '../../../data/Constants';
import { IUserManipulationProps } from '../../../../types';
import { useAuth } from 'oidc-react';
import { displayMessage } from '../../DisplayMessage';

const nameRegex = /^((\([A-Za-z\s]+\)+(\s+))|([A-Za-z0-9]+(,\s|\s))|[A-Za-z0-9])+[A-Za-z0-9]+((,\s[A-Za-z0-9])|(\s(\([A-Za-z\s]+\))))?$/
const emailRegex = /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}$/
const uniqueKeyForRole:string = foreignKeysForTable['roles'];

const loadFormData = async ( props, id, formData, setFormData, initialUserData, setInitialUserData, setSelected ) => {
  let userDetails = await props.getUserDetails( props.token,[id] )
  if( !userDetails.data ) {
    return null;
  }
  userDetails = userDetails.data[0];
  setFormData( {...formData,'name':userDetails.name,'emailId':id,'isAdmin':userDetails.isAdmin,'isActive':userDetails.isActive,'isService':userDetails.isService} )
  const assignedRoles = userDetails.roles.map( role =>{
    return role[uniqueKeyForRole]
  } ) 
  setInitialUserData( {...initialUserData,
    'personalDetails':{'name':userDetails.name,'emailId':id,'isAdmin':userDetails.isAdmin,'isActive':userDetails.isActive,'isService':userDetails.isService},
    'roles':assignedRoles} )
  setSelected( assignedRoles );
}

const loadInitialData = ( functionProps ) => {
  const props = functionProps.props
  if( functionProps.manipulationType === EActionType.Edit ) {
    functionProps.setFormData( {...functionProps.formData,'emailId':functionProps.id} )
    functionProps.loadFormData( props,functionProps.id,functionProps.formData,functionProps.setFormData,functionProps.initialUserData,functionProps.setInitialUserData,functionProps.setSelected )
  }
  props.getRoles( props.token,1,RecordLength[RecordLength.length - 1],null,'ALL' );
}

const addDefaultRole = ( defaultRole, manipulationType, selected, setSelected ) => {
  if( defaultRole && manipulationType === EActionType.Create ) {
    setSelected( [...selected,defaultRole] )
  }
}

const setAlertMessage = ( props, setDisplayAlertMessage ) => {
  if( props.error.code && ( props.error.action === EAction.Create || props.error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }    
}

const formValidation = ( formData, selected, initialUserData, setDisplayNameErrorMessage, setDisplayEmailErrorMessage, setDisableButton ) => {
  const name = formData.name.trimEnd();
  const validName = name.length > 2 && nameRegex.test( name ) && name.split( '(' ).length <= 2;
  const validEmail = emailRegex.test( formData.emailId.toLowerCase() );
  setDisplayNameErrorMessage( !validName );
  setDisplayEmailErrorMessage( !validEmail );
  const personalDetailsUpdated = JSON.stringify( {...formData,name} ) !== JSON.stringify( initialUserData.personalDetails )
  const rolesUpdated = JSON.stringify( selected.sort() ) !== JSON.stringify( initialUserData.roles.sort() )
  setDisableButton( !validName || !validEmail || !personalDetailsUpdated && !rolesUpdated )
}

const handleChange = ( event: any, formData, setFormData ) => {
  const name = event.target.name;
  let value = '';
  switch( name ) {
    case 'isAdmin':
      value = event.target.checked;
      break;
    case 'isActive':
      value = event.target.checked;
      break;
    case 'isService':
      value = event.target.checked;
      break;
    case 'emailId':
      value = event.target.value.toLowerCase();
      break;
    default:
      value = event.target.value;
  }
  setFormData( {...formData, [name] :value} )
}

const handleSelectAllClick = ( event: any, props, defaultRole, manipulationType, initialUserData, setSelected ) => {
  if( event.target.checked ) {
    const newSelected = props.roles.data.map( ( n ) => n[uniqueKeyForRole] );
    setSelected( newSelected );
  } else if( initialUserData.roles?.includes( defaultRole ) || manipulationType === EActionType.Create ) {
    setSelected( [defaultRole] )
  } else{
    setSelected( [] );
  }
}

const handleClick = ( event, value, selected, setSelected ) => {
  const selectedIndex = selected.indexOf( value );
  let newSelected: readonly string[] = [];
  if ( selectedIndex === -1 ) {
    newSelected = newSelected.concat( selected, value );
  } else if ( selectedIndex === 0 ) {
    newSelected = newSelected.concat( selected.slice( 1 ) );
  } else if ( selectedIndex === selected.length - 1 ) {
    newSelected = newSelected.concat( selected.slice( 0, -1 ) );
  } else if ( selectedIndex > 0 ) {
    newSelected = newSelected.concat(
      selected.slice( 0, selectedIndex ),
      selected.slice( selectedIndex + 1 ),
    );
  }
  setSelected( newSelected );
};

const handleAPIResponse = ( res, props, errorMessage:string, successMessage:string ) => {
  const users = AppStore.getState().administartion.users;
  if( res.error ) {
    const message = res.error.message ? res.error.message : errorMessage
    props.setAlertMessage( {show:true,message:message,type:'error'} )
  }else{
    props.getUsers( props.token,users.currentPage,users.recordsPerPage,users.searchKey )
    props.setAlertMessage( {show:true,message:successMessage,type:'success'} )
    props.closeDialog( false )
  }
}

const handleCreate = ( props, formData, selected, t ) => {
  props.createUser( props.token,{'userInfo':{...formData,name:formData.name.trimEnd()},'roles':selected} ).then( res=>{
    handleAPIResponse( res, props, t( 'messages.fail.create' ), t( 'messages.success.create' ) )
  } )
}

const handleUpdateUserDetails = ( props, formData, t ) => {
  props.updateUser( props.token,{...formData,name:formData.name.trimEnd()} ).then( res=>{
    handleAPIResponse( res, props, t( 'messages.fail.update' ), t( 'messages.success.update' ) )
  } )
}

const handleUpdateUserRoles = ( props, formData, selected, t ) => {
  props.updateUserRoleAssociation( props.token,{'emailId':formData.emailId,'roles':selected} ).then( res=>{
    handleAPIResponse( res, props, t( 'messages.fail.update' ), t( 'messages.success.update' ) )
  } )
}

const handleUpdateCompleteUser = ( props, formData, selected, t ) => {
  props.updateUser( props.token,{...formData,name:formData.name.trimEnd()} ).then( res=>{
    if( res.error ) {
      const message = res.error.message ? res.error.message : t( 'messages.fail.update' )
      props.setAlertMessage( {show:true,message:message,type:'error'} )
    }else{
      handleUpdateUserRoles( props,formData, selected, t )
    }
  } )
}

const handleSubmit = ( handleSubmitProps:any ) => {    
  const { event, props, formData, selected, initialUserData, manipulationType, t,setDisplayAlertMessage } = handleSubmitProps;
  event.preventDefault();
  const personalDetailsUpdated = JSON.stringify( {...formData,name:formData.name.trimEnd()} ) !== JSON.stringify( initialUserData.personalDetails )
  const rolesUpdated = JSON.stringify( selected.sort() ) !== JSON.stringify( initialUserData.roles.sort() )
  props.resetAlertMessage( );
  props.resetError();
  setDisplayAlertMessage( false );
  switch( manipulationType ) {
    case EActionType.Create:
      handleCreate( props, formData, selected, t )
      break;
    case EActionType.Edit:
      if( personalDetailsUpdated && rolesUpdated ) {
        handleUpdateCompleteUser( props, formData, selected, t )
      } else if( personalDetailsUpdated ) {
        handleUpdateUserDetails( props, formData ,t )
      } else if( rolesUpdated ) {
        handleUpdateUserRoles( props,formData, selected, t )
      }
      break;        
  }
}

const disableRole = ( row, manipulationType:string, initialUserData ) => {  
  if( manipulationType === EActionType.Edit ) {
    if( !initialUserData.personalDetails.isActive ) {
      return true
    } else if( initialUserData.roles && row.isDefault ) {
      return initialUserData.roles.includes( row[uniqueKeyForRole] )
    }
  } else if( manipulationType === EActionType.Create && row.isDefault ) {
    return true;
  }
  return false;  
}

function getHeader( manipulationType:string,t:( arg: string, arg2?: object ) => string ) {
  return manipulationType === EActionType.Create ? t( 'labels.createUser' ) : t( 'labels.editUser' )  
}

const handleClose = ( event, props, setDisplayAlertMessage, reason? ) => {
  if ( reason === 'clickaway' ) {
    return;
  }
  props.resetAlertMessage( );
  props.resetError();
  setDisplayAlertMessage( false );
}

const createCell = ( row,c )=>{
  if ( c.type === 'checkbox' ) {
    return <Checkbox value={ row[c.field] } checked={ row[c.field] } disabled/> 
  } else {
    return row[c.field];
  }
}

export const $UserManipulation = ( props:IUserManipulationProps ) => {

  const {t} = useTranslation();
  const auth = useAuth();
  const [tabValue,setTabValue] = useState( 'PersonalDetails' );
  const [formData,setFormData] = useState( {'name':'','emailId':'','isAdmin':false,'isActive':true,'isService':false} );
  const [disableButton,setDisableButton] = useState( true );
  const [displayNameErrorMessage,setDisplayNameErrorMessage] = useState( false );
  const [displayEmailErrorMessage,setDisplayEmailErrorMessage] = useState( false );
  const [displayAlertMessage,setDisplayAlertMessage] = useState( false );  
  const [selected, setSelected] = React.useState<string[]>( [] );
  const [defaultRole,setDefaultRole] = React.useState<string>( );
  const [initialUserData,setInitialUserData] = useState( {'personalDetails':{ 'name':'','emailId':'','isAdmin':false,'isActive':true,'isService':false },'roles':[] } )
  
  const manipulationType = props.type; //To get the type of manipulation being performed
  const id = manipulationType === EActionType.Edit && props.id;
  const header = getHeader( manipulationType,t );

  const rowCount = props.roles.data.length;
  const isSelected = ( value: string ) => selected.indexOf( value ) !== -1;  
  const headers = TableHeaders['roleAssociationTab'].main;
  

  useEffect( ()=>{
    loadInitialData( {props, id, header, manipulationType, formData, setFormData, initialUserData, setInitialUserData, loadFormData, setSelected } )
  },[] )

  useEffect( ()=>{
    const tempDefaultRole:string = props.roles.data?.find( r=>r.isDefault )?.[uniqueKeyForRole];
    setDefaultRole( tempDefaultRole )
  },[props.roles] )

  useEffect( ()=>{
    addDefaultRole( defaultRole, manipulationType, selected, setSelected )
  },[defaultRole] )

  useEffect( () => { 
    setAlertMessage( props, setDisplayAlertMessage );
  }, [props.error] ) 

  useEffect( ()=>{
    formValidation( formData, selected, initialUserData, setDisplayNameErrorMessage, setDisplayEmailErrorMessage, setDisableButton )
  } )

  const handleTabChange = ( ...params:[React.BaseSyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  if( !props.users ) {
    return null;
  }  

  return <>
    <Box className="userManipulationTabs">
      <TabContext value={ tabValue || 'PersonalDetails' }>
        <Box className="dialog-box">
          <TabList onChange={ handleTabChange } >
            <Tab className="dialog-tab text-capitalize"
              value="PersonalDetails" label={ t( 'labels.personalDetails' ) }
            />
            <Tab className="dialog-tab text-capitalize" 
              value="RoleAssociation" label={ t( 'labels.roleAssociation' ) }
            />
          </TabList>
        </Box>

        <TabPanel value="PersonalDetails" sx={ {} } className="personalDetailsTab" >
          <Box className="personalDetailsForm">
            <Collapse in={ displayAlertMessage } className="show-alert" >
              <Alert className="errorMessage" severity="error" onClose={ ( event ) => {
                handleClose( event, props, setDisplayAlertMessage )
              } }
              >
                <AlertTitle>{ displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
              </Alert>
            </Collapse>
            <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" fullWidth value={ formData.name } onChange={ ( e )=>{
              handleChange( e, formData, setFormData )
            } } disabled={ manipulationType === EActionType.Edit && !formData.isActive }
            /><br/>
            <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
              <Alert className="errorMessage" severity="error">
                <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
              </Alert>
            </Collapse><br/>

            <TextField name="emailId" label={ t( 'labels.email' ) } required variant="outlined" size="small" fullWidth value={ formData.emailId } onChange={ ( e )=>{
              handleChange( e, formData, setFormData )
            } } disabled={ manipulationType === EActionType.Edit }
            /><br/>
            <Collapse in={ displayEmailErrorMessage && formData.emailId.length > 0 }>
              <Alert className="errorMessage" severity="error">
                <AlertTitle>{t( 'messages.emailErrorMessage' )}</AlertTitle>
              </Alert>
            </Collapse><br/>

            <FormControlLabel
              value={ formData.isAdmin }
              checked={ formData.isAdmin }
              disabled = { manipulationType === EActionType.Edit && !formData.isActive }
              control={ <Checkbox /> }
              label={ t( 'labels.isAdmin' ) }
              labelPlacement="end"
              onChange={ ( e )=>{
                handleChange( e, formData, setFormData ) 
              } }
              name="isAdmin"
            />

            <FormControlLabel
              value={ formData.isService }
              checked={ formData.isService }
              disabled = { manipulationType === EActionType.Edit && !formData.isActive }
              control={ <Checkbox /> }
              label={ t( 'labels.isService' ) }
              labelPlacement="end"
              onChange={ ( e )=>{
                handleChange( e, formData, setFormData ) 
              } }
              name="isService"
            />            
            
            {!initialUserData.personalDetails.isActive && manipulationType === EActionType.Edit ?
              <><FormControlLabel
                value={ formData.isActive }
                checked={ formData.isActive }
                control={ <Checkbox /> }
                label={ t( 'labels.isActive' ) }
                labelPlacement="end"
                onChange={ ( e )=>{
                  handleChange( e, formData, setFormData )
                } }
                name="isActive"
              />
              <br/></> : null
            }
          </Box>
        </TabPanel>

        <TabPanel value="RoleAssociation" className="roleAssociationTab">
          <Collapse in={ displayAlertMessage } className="show-alert">
            <Alert className="errorMessage" severity="error" onClose={ ( event )=> {
              handleClose( event, props, setDisplayAlertMessage )
            } }
            >
              <AlertTitle>{ displayMessage( props.error,t,manipulationType,auth )} </AlertTitle>
            </Alert>
          </Collapse> 
          <TableContainer component={ Paper } className="data-table">
            <Table stickyHeader aria-label="simple table">
              <TableHead>          
                <TableRow >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={ selected.length > 0 && selected.length < rowCount }
                      checked={ rowCount > 0 && selected.length === rowCount }
                      onChange={ ( e )=>{
                        handleSelectAllClick( e, props, defaultRole, manipulationType, initialUserData, setSelected )
                      } }
                      disabled = { manipulationType === EActionType.Edit && !initialUserData.personalDetails.isActive }
                      inputProps={ {
                        'aria-label': 'select all roles',
                      } }
                    />
                  </TableCell>
                  {headers.map( ( row ) => 
                    <TableCell key={ row.field }>{t( 'labels.' + row.field )}</TableCell>
                  )}
                </TableRow>    
              </TableHead>
              
              <TableBody>
                {props.roles.data.map( ( row,index ) =>{
                  const isItemSelected = isSelected( row[uniqueKeyForRole] ); 
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <TableRow
                    sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                    hover            
                    role="checkbox"
                    aria-checked={ isItemSelected }
                    tabIndex={ -1 }
                    key={ row[uniqueKeyForRole] }
                    selected={ isItemSelected }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={ initialUserData.personalDetails.isActive && isItemSelected }
                        inputProps={ {
                          'aria-labelledby': labelId,
                        } }
                        onClick={ ( event ) => handleClick( event, row[uniqueKeyForRole], selected, setSelected ) }
                        disabled = { disableRole( row, manipulationType, initialUserData ) }
                      />
                    </TableCell>
                    {headers.map( ( c ) => 
                      <TableCell key={ c.field } component="th" scope="row">
                        {createCell( row,c )}                  
                      </TableCell>              
                    )}
                  </TableRow>
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>

      <Box className="formActionButtons">
        <Button variant="contained" size="medium" className="text-capitalize" disabled={ disableButton } onClick={ ( event )=>{
          handleSubmit( {event, props, formData, selected, initialUserData, manipulationType, t,setDisplayAlertMessage} )
        } }
        >
          { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
        </Button>
        <Button size="medium" className="text-capitalize" onClick={ ()=> {
          props.closeDialog( false );
          props.resetAlertMessage( );
          props.resetError(); 
          setDisplayAlertMessage( false );
        } }
        >{ t( 'button.cancel' ) } </Button>
      </Box>
    </Box>
  </>
}

export const UserManipulation = connect( Combine( userState, roleState,alertMessageState,errorState ),Combine( userDispatcher, roleDispatcher,alertMessageDispatcher,errorDispatcher ) )( $UserManipulation ) ;